export const APP_COMING_SOON = !!process.env.REACT_APP_COMING_SOON;
export const APP_ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT || 'development';

export type SalahSlotType = {
  salah: string,
  description: string,
  startLabel: string,
}
export const SALAH_SLOTS = [
  {
    salah: 'eid',
    description: '',
    startLabel: "Jama'ah",
  },
  {
    salah: 'jumuah',
    description: '',
    startLabel: 'Khutbah',
  },
  {
    salah: 'isha',
    description: '',
    startLabel: "Jama'ah",
  },
];
