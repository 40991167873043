import axios from 'axios';
import { API_URL } from '../config/apiConfig';
import {
  getDataFromResponse,
  responseErrorHandler,
} from '../config/axiosConfig';
import SlotRequest from '../models/SlotRequest';
import Slot from '../models/Slot';
import MosquePrayer from '../models/MosquePrayer';

const ENDPOINT_PATH = '/prayer';

class MosquePrayersService {
  createPrayerSlot = async (slots: Array<SlotRequest>): Array<Slot> => {
    const response = await axios
      .post(API_URL + ENDPOINT_PATH, {
        slots: slots,
      })
      .catch((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return data.map((d) => Object.assign(new Slot(), d));
  };

  getPrayerSlots = async (): Array<Slot> => {
    const response = await axios
      .get(API_URL + ENDPOINT_PATH)
      .catch((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return data.map((d) => Object.assign(new Slot(), d));
  };

  updatePrayerSlot = async (slotId: string, slot: SlotRequest): Slot => {
    const response = await axios
      .put(API_URL + ENDPOINT_PATH + '/' + slotId, slot)
      .catch((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new Slot(), data);
  };

  deletePrayerSlot = async (slotId: string, slot: SlotRequest): Slot => {
    return await axios
      .delete(API_URL + ENDPOINT_PATH + '/' + slotId)
      .then((response) => getDataFromResponse(response))
      .catch((err) => responseErrorHandler(err));
  };
}

export default new MosquePrayersService();
