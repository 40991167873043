import React, {useContext, useEffect, useState} from 'react';
import {Frame, Page} from 'framer';
import ViewWrapper from '../../components/theme/ViewWrapper';
import {AuthContext} from '../../providers/AuthProvider';
import Slot from '../../models/Slot';
import ViewWithHeading, {AdditionalHeadingView} from '../../components/ViewWithHeading';
import BookingPanelDetail, {
  BookingPanelDetailTitle,
} from '../bookings/BookingPanelDetail';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';
import SelectInput from '../../components/input/SelectInput';
import map from 'lodash/map';
import SlotCard from './SlotCard';
import PageIndicators from '../../components/PageIndicators';
import mosquePrayersService from '../../services/mosquePrayersService';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/free-solid-svg-icons';
import routes from '../../constants/routes';
import {useHistory} from 'react-router';
import styled from 'styled-components';
import TextWrapper from '../../components/theme/TextWrapper';
import {SALAH_SLOTS} from '../../config/appConfig';
import {Drawer, useToast} from '@chakra-ui/react'
import {MosquePrayerSlotsGenericForm} from './MosquePrayerSlotsGenericForm';
import {
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader, DrawerOverlay,
} from '@chakra-ui/modal';

function SlotsCardView(props) {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  const [currentPage, setCurrentPage] = useState(0);
  const [mosque, setMosque] = useState(null);
  const [slots : Array<Slot>, setSlots] = useState([]);
  const [salahFilter, setSalahFilter] = useState("");
  const [error, setError] = useState(null);
  const [deletingSlotId, setDeletingSlotId] = useState(null);
  const [slotEdit, setSlotEdit] = useState(null);
  const toast = useToast()

  useEffect(() => {
    if (authContext != null) {
      setError(null);
      setSalahFilter("");
      setSlots(authContext.slots);
    }
  }, [authContext, authContext.slots]);

  useEffect(() => {
    setSlots(authContext.slots.filter(slot => salahFilter !== "" ? slot.salah === salahFilter : true));
  }, [salahFilter]);

  useEffect(() => {
    setCurrentPage(0);
  }, [slots]);


  function onChangePage(currentIndex, previousIndex) {
    setCurrentPage(currentIndex);
  }

  function onDelete(slot: Slot) {
    setError(null);
    setDeletingSlotId(slot.slot_id);
    mosquePrayersService.deletePrayerSlot(slot.slot_id)
        .then(() => {
          const newSlots = slots.filter(s => s.slot_id !== slot.slot_id);
          authContext.setSlots(newSlots);
    })
        .catch((e) => setError(e.message))
        .finally(() => setDeletingSlotId(null))
  }

  function onUpdate(slot: Slot) {
    console.log("updating slots")
    setError(null);
    setDeletingSlotId(slot.slot_id);
    mosquePrayersService.updatePrayerSlot(slotEdit.slot_id, {...slot, salah: slotEdit.salah})
        .then((newSlots) => {
          // const filteredSlots = slots.map(s => s.slot_id === slotEdit.slot_id ? {s, ...newSlots[0]} : s)
          authContext.refreshSlots()
              .then(() => {
                setSlotEdit(null)
                toast(({
                  title: "Updated slot successfully",
                  description: "",
                  status: "success"
                }))
              })
              .catch(e =>
                  toast(({
                    title: "Failed to update slot",
                    description: e.message,
                    status: "error"
                  })))
    })
        .catch((e) => setError(e.message))
        .finally(() => setDeletingSlotId(null))
  }

  return (
      <ViewWithHeading>
        <View>
        <AdditionalHeadingView>
          <BookingPanelDetail icon={faMosque} isDark={true}>
            <BookingPanelDetailTitle>
              Filter By Salah
            </BookingPanelDetailTitle>
          </BookingPanelDetail>
          <ViewWrapper style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%'}}>
            <SelectInput onChange={(event) => setSalahFilter(event.target.value)} value={salahFilter}>
              <option value={""}>All Salah Slots</option>
              {map(SALAH_SLOTS, (salahSlot) => <option key={salahSlot.salah} value={salahSlot.salah}>{salahSlot.salah}</option>)}
            </SelectInput>
            <SecondaryButton small onClick={() => history.push(`${routes.SLOTS_ADD}?salah=${salahFilter}`)}><FontAwesomeIcon icon={faPlusCircle} color={'white'} size={'sm'}/> New Slot</SecondaryButton>
          </ViewWrapper>
          {error && <ErrorAlert>{error}</ErrorAlert>}
        </AdditionalHeadingView>
        <ViewWrapper
            style={{
              display: 'flex',
              flexDirection: 'column',
              flex: 1,
              position: "relative",
              height: "95%",
              marginBottom: "20px",
            }}>
          <ViewWrapper style={{
            flex: 1,
            height: '95%',
            position: 'relative'
          }}>
            <Page width={"100%"} height={"100%"} gap={20} padding={10} onChangePage={onChangePage} currentPage={currentPage} style={{maxWidth: '600px'}}>
              {slots.map(slot => <SlotCard slot={slot} onDelete={onDelete} onEdit={(slot) => setSlotEdit(slot)} loadingMessage={deletingSlotId === slot.slot_id ? 'Deleting Slot' : null}/>)}
              <AddCard onTap={() => history.push(`${routes.SLOTS_ADD}?salah=${salahFilter}`)}>
                <FontAwesomeIcon icon={faPlusCircle} color={'white'} size={'3x'}/>
                <TextWrapper>Add New Slot</TextWrapper>
              </AddCard>
            </Page>
          </ViewWrapper>
          <PageIndicators currentPage={currentPage} pages={slots.length+1}/>
        </ViewWrapper>
        </View>

        <Drawer
            isOpen={slotEdit != null}
            onClose={() => setSlotEdit(null)}
            placement="bottom"
        >
          <DrawerOverlay>
            <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader>Edit Slots</DrawerHeader>

            <DrawerBody>
              <MosquePrayerSlotsGenericForm salahSlotType={salahFilter}
                                            slot={slotEdit}
                                            onComplete={onUpdate}/>
            </DrawerBody>
          </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </ViewWithHeading>
  )
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  flex: 1;
  align-self: center;
`;

const AddCard = styled(Frame)`
  border-radius: 30px;
  border: white dashed 1px;
  background-color: rgba(0,0,0,0.3) !important;
  height: 100% !important;
  margin-bottom: 20px;
  padding: 30px 20px;x;
  display: flex !important;;
  flex: 1 !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0,0,0,0.3) 0 0 10px;
  color: white;
  &:last-child {
  margin-right: 0;
  }
`;

export default SlotsCardView;
