import React, { Fragment, useContext, useEffect, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import styled from 'styled-components';
import { AuthContext } from '../../../providers/AuthProvider';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../../components/ViewWithHeading';
import BottomCardView from '../../../components/BottomCardView';
import { useHistory } from 'react-router';
import { faMosque } from '@fortawesome/free-solid-svg-icons/faMosque';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons/faLocationArrow';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons/faStickyNote';
import SlotDetails from './SlotDetails';
import {
  DetailsInfoBlock,
  DetailsHeading,
  DetailsInfo,
  DetailsInfoTitle, Details,
} from '../../../components/InformationDetailComponents';
import InfoAlert from '../../../components/alerts/InfoAlert';
import routes from '../../../constants/routes';
import MosqueWithSlots from '../../../models/MosqueWithSlots';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import SuccessAlert from '../../../components/alerts/SuccessAlert';
import TextWrapper from '../../../components/theme/TextWrapper';
import HeadingText from '../../../components/text/HeadingText';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faHourglassStart } from '@fortawesome/free-solid-svg-icons/faHourglassStart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SuccessButton from '../../../components/buttons/SuccessButton';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import MosqueSignUpView from '../mosquesignup/MosqueSignUpView';

const VerifiedIcon = ({ isVerified }) => (
  <FontAwesomeIcon
    icon={isVerified ? faCheckCircle : faHourglassStart}
    size='3x'
    color={isVerified ? 'green' : 'red'}
  />
);

function MosqueDetailsView() {
  const [error, setError] = useState(null);
  const [mosque: MosqueWithSlots, setMosque] = useState(false);
  const [isVerified, setIsVerified] = useState(true);

  const authContext = useContext(AuthContext);
  const history = useHistory();

  function signOut() {
    authContext.signOut().then(() => history.push(routes.HOME));
  }

  useEffect(() => {
    const mosque = authContext.getMosque();
    if (mosque == null) {
      setError('You have not signed up your mosque yet, please fill the form below to proceed.');
    } else if (mosque.pending === 'true' || mosque.pending) {
      setIsVerified(false);
      setError(
        'Please add in your Salah times below. Your Mosque is pending approval. Once approved you will be given a unique URL to share with your attendees.'
      );
    }

    setMosque(mosque);
  }, [null]);

  function renderView() {
    if (authContext.isLoggedIn()) {
      if (mosque == null) {
        return <MosqueSignUpView/>;
      } else {
        return (
            <Fragment>
              <VerifiedIcon isVerified={isVerified}/>
              <View>
                <ShareableLink mosque={mosque}/>
                <hr/>
                <SuccessButton
                    onClick={() => history.push(routes.BOOKINGS_VERIFY)}>Verify
                  Bookings Now</SuccessButton>
                <DetailsHeading style={{marginTop: '15px'}}>Mosque
                  Details</DetailsHeading>
                <DetailsInfoBlock>
                  <DetailsInfoTitle icon={faMosque} title={'Mosque Name'}/>
                  <DetailsInfo>{mosque.name}</DetailsInfo>
                </DetailsInfoBlock>
                <DetailsInfoBlock>
                  <DetailsInfoTitle icon={faLocationArrow} title={'Address'}/>
                  {/*<MosqueMap mosqueWithSlots={mosque}/>*/}
                  <DetailsInfo>{mosque.address}</DetailsInfo>
                </DetailsInfoBlock>
                <DetailsInfoBlock>
                  <DetailsInfoTitle
                      icon={faStickyNote}
                      title={'Additional Information'}
                  />
                  <DetailsInfo
                      style={{whiteSpace: "pre-wrap"}}>{mosque.notes}</DetailsInfo>
                </DetailsInfoBlock>
                <hr/>
                {/*<DetailsHeading>Salah Details</DetailsHeading>*/}
                {/*<SlotDetails mosque={mosque} />*/}
                <Details>
                  <DetailsInfo label={'Name'}
                               title={authContext.user.name || 'N/A'}/>
                  <DetailsInfo
                      label={'E`mail'}
                      title={authContext.user.email || 'N/A'}
                  />
                  <DetailsInfo
                      label={'Phone Number'}
                      title={authContext.user.phone || 'N/A'}
                  />
                  <DetailsInfo label={'UID'}
                               title={authContext.user.uid || 'N/A'}/>
                </Details>

                <ViewWrapper style={{paddingBottom: '20px'}}/>

                <InfoAlert>
                  To delete your account or request your data please contact us
                  at{' '}
                  <a href={'mail:salahspace.app@gmail.com'}>salahspace.app@gmail.com</a>
                  <br/>
                  For more information you can read our
                  {' '}<a
                    target={'_blank'}
                    rel='noopener noreferrer'
                    href={'https://about.salahspace.app/terms-conditions'}>
                  terms and conditions
                </a>{' '}
                  and{' '}
                  <a
                      target={'_blank'}
                      rel='noopener noreferrer'
                      href={'https://about.salahspace.app/privacy-policy'}>
                    privacy policy
                  </a>{' '}
                  provided by Salah Space.
                </InfoAlert>

                <ViewWrapper style={{paddingBottom: '30px'}}/>

              </View>
            </Fragment>
        )
      }
    } else {
      return <ErrorAlert>You need to <b>Sign In</b> to continue</ErrorAlert>
    }
  }

  return (
    <ViewWithHeading title={'My Mosque Details'}>
      <AdditionalHeadingView style={{ paddingTop: '100px' }}>
        {error && <InfoAlert>{error}</InfoAlert>}
      </AdditionalHeadingView>
      <BottomCardView style={{ alignItems: 'center', flex: 1 }}>
        {renderView()}
        <PrimaryButton onClick={signOut}>Sign Out</PrimaryButton>
        <FormInput
            style={{
              width: '100%',
              paddingTop: '10px',
              borderTop: 'solid #e1e1e1 1px',
              marginTop: '10px',
            }}>
        </FormInput>
      </BottomCardView>
    </ViewWithHeading>
  );
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const ShareableLink = (props: { mosque: MosqueWithSlots }) => {
  const [copied, setCopied] = useState(false);
  const url = `https://worshippers.salahspace.app/mosque/book/${props.mosque.slug}`;

  return (
    <ViewWrapper
      style={{
        fontWeight: 700,
        paddingBottom: '20px',
        textAlign: 'center',
        overflowWrap: "anywhere",
        wordBreak: 'break-all'
      }}>
      {props.mosque.pending === 'true' || props.mosque.pending ? null : (
        <Fragment>
          <HeadingText isDark={true}>Your shareable URL</HeadingText>
          <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
            <TextWrapper
              style={{
                fontSize: '1.1em',
                padding: '10px',
                textAlign: 'center',
                border: 'solid 1px #e1e1e1',
                borderRadius: '10px',
                width: '100%',
                textDecoration: 'underlined',
                wordBreak: 'break-all'
              }}>
              {url}
            </TextWrapper>
          </CopyToClipboard>
          <TextWrapper style={{ textAlign: 'center' }}>
            Click to copy
          </TextWrapper>
          {copied && (
            <SuccessAlert style={{ fontSize: '0.5em' }}>
              Copied to Clipboard
            </SuccessAlert>
          )}
        </Fragment>
      )}
    </ViewWrapper>
  );
};

export default MosqueDetailsView;
