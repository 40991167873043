import React from 'react';
import styled from 'styled-components';
import {COLOR_SUCCESS} from '../../constants/styles';
import BaseButton from './BaseButton';

const SuccessButton = styled(BaseButton)`
  border-radius: 10px;
  border-color: ${COLOR_SUCCESS};
  border-style: solid;
  background-color: ${COLOR_SUCCESS};
  color: white;
`;

export default SuccessButton;