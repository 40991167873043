import axios from 'axios';
import {API_URL} from '../config/apiConfig';
import MosqueBooking from '../models/MosqueBooking';
import MosqueBookingRequest from '../models/MosqueBookingRequest';
import MosqueBookingResponse from '../models/MosqueBookingResponse';
import {
  getDataFromResponse,
  isResponse2XX,
  responseErrorHandler,
} from '../config/axiosConfig';
import Logger from '../Logger';
import VerificationResponse from '../models/VerificationResponse';


const ENDPOINT_PATH = "/verify";
class VerifyBookingService {

  constructor() {
    this.logger = new Logger(VerifyBookingService.name);
  }

  postVerifyQRBooking = async (qr_code_content: string, slotId: string) : VerificationResponse => {
    const response = await axios.post(API_URL + ENDPOINT_PATH, {
      qr_code_content: qr_code_content,
      prayer_slot_id: slotId,
      }).catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new VerificationResponse(), data);
  };
  
  postVerifyCodeBooking = async (booking_number: string, slotId: string) : VerificationResponse => {
    const response = await axios.post(API_URL + ENDPOINT_PATH, {
      booking_number: booking_number,
      prayer_slot_id: slotId,
      }).catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new VerificationResponse(), data);
  };

}

export default new VerifyBookingService();