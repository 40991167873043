import React from 'react';

export const TimeDataListJumuah = ({ id }) => {
  const Hours = [11, 12, 1, 2, 3, 4];

  const minutesInterval = 5;
  const Minutes = Array.from(Array(60 / minutesInterval).keys(), (n) =>
    (n * minutesInterval).toString().padStart(2, '0')
  );

  const listItems = Hours.map((intHour) =>
      Minutes.map((minute) => {
        const hour = intHour.toString().padStart(2, '0');
        const time = `${hour}:${minute} ${hour === '11' ? 'am' : 'pm'}`;
        return <option key={time} value={time} />;
      })
    );

  return <datalist id={id}>{listItems}</datalist>;
};

export const TimeDataListEid = ({ id }) => {
  const AmPm = ['am'];
  const Hours = [4, 5, 6, 7, 8, 9, 10, 11, 12];

  const minutesInterval = 5;
  const Minutes = Array.from(Array(60 / minutesInterval).keys(), (n) =>
    (n * minutesInterval).toString().padStart(2, '0')
  );

  const listItems = Hours.map((intHour) =>
      Minutes.map((minute) => {
        const hour = intHour.toString().padStart(2, '0');
        const time = `${hour}:${minute} ${hour === '12' ? 'pm' : 'am'}`; 
        // ternary operator left in case we decide to include Eid prayer past noon.
        return <option key={time} value={time} />;
      })
    );

  return <datalist id={id}>{listItems}</datalist>;
};

export const TimeDataListIsha = ({ id }) => {
  const AmPm = ['pm'];
  // const Hours = [6, 7, 8, 9, 10, 11, 12, 1, 2]; - Original, will put back in place once backend has been updated.
  const Hours = [6, 7, 8, 9, 10, 11];

  const minutesInterval = 5;
  const Minutes = Array.from(Array(60 / minutesInterval).keys(), (n) =>
    (n * minutesInterval).toString().padStart(2, '0')
  );

  const listItems = AmPm.map((ampm) =>
    Hours.map((intHour) =>
      Minutes.map((minute) => {
        const hour = intHour.toString().padStart(2, '0');
        const time = `${hour}:${minute} ${hour === '12' ? 'am' : hour === '01' ? 'am' : hour === '02' ? 'am' : AmPm[0]}`; 
        return <option key={time} value={time} />;
      })
    )
  );

  return <datalist id={id}>{listItems}</datalist>;
};