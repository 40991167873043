import React, {Fragment, useContext} from 'react';
import { Route } from 'react-router';
import routes from '../../constants/routes';
import LoginView from './LoginView';
import EmailSignInView from './email/EmailSignInView';
import EmailSignUpView from './email/EmailSignUpView';
import EmailPasswordResetView from './email/EmailPasswordResetView';
import {AuthContext} from '../../providers/AuthProvider';
import LoadingCircles from '../../components/loading/LoadingCircles';

function LoginRouter() {

  const authContext = useContext(AuthContext);

  if(authContext.loading) {
    return <LoadingCircles>Signing you in...</LoadingCircles>
  } else {
    return (
        <Fragment>
          <Route exact path={routes.LOGIN_EMAIL_SIGN_UP}>
            <EmailSignUpView />
          </Route>
          <Route exact path={routes.LOGIN_EMAIL}>
            <EmailSignInView />
          </Route>
          <Route path={routes.LOGIN_EMAIL_PASSWORD_RESET}>
            <EmailPasswordResetView />
          </Route>
          <Route exact path={routes.LOGIN}>
            <LoginView />
          </Route>
        </Fragment>
    )
  }
}

export default LoginRouter;
