import axios from 'axios';
import { API_AUTH_HEADER } from './apiConfig';
import Logger from '../Logger';
import Mosque from '../models/Mosque';

const NETWORK_UNEXPECTED_ERROR = "Something very unexpected happened, please try again or get in touch with us.";
const NETWORK_NO_RESPONSE_ERROR = "Looks like our services might be down, please try again or get in touch with us.";
const NETWORK_NON_200_DEFAULT_ERROR = "Something went wrong with your request, please try again or get in touch with us.";
const NETWORK_NO_DATA_ERROR = "We could not retrieve any data for you request, please try again or get in touch with us.";

const logger = new Logger("axiosConfig");

function addAxiosAuthHeader(token : string) {
  logger.info("Adding axios header", API_AUTH_HEADER, token);
  axios.defaults.headers.common[API_AUTH_HEADER] = 'Bearer ' + token;
}

function isResponse2XX(response) {
  return response != null && response.status != null && response.status.toString().startsWith("2");
}

function getDataFromResponse(response) {
  let result;
  if (!isResponse2XX(response.status)) {
    const data = response.data;
    if (data != null && data.result != null) {
      result = data.result;
    } else {
      logger.error("Failed to get data", response);
      throw new Error(NETWORK_NO_DATA_ERROR)
    }
  } else {
    logger.error("Non 200 response received", response);
    throw new Error(NETWORK_NON_200_DEFAULT_ERROR)
  }

  logger.info("Received data", result);
  return result;
}

function responseErrorHandler(error, defaultMessage) {
  logger.error("error", error);
  logger.error("error response", error.response);
  if (error.response) {
    // Request made and server responded
    logger.error("Non 200 response received", error.response);
    const data = error.response.data;
    if (data != null) {
      if (data.messages != null && typeof data.messages === 'string') {
        throw new Error(data.messages);
      } else {
        throw new Error(defaultMessage || NETWORK_NON_200_DEFAULT_ERROR);
      }
    } else {
      throw new Error(defaultMessage || NETWORK_NON_200_DEFAULT_ERROR);
    }
  } else if (error.request) {
    // The request was made but no response was received
    logger.error("Non response from server", error.request);
    throw new Error(NETWORK_NO_RESPONSE_ERROR);
  } else {
    // Something happened in setting up the request that triggered an Error
    logger.error("Something wrong on network request", error);
    throw new Error(NETWORK_UNEXPECTED_ERROR);
  }
}

export {
  addAxiosAuthHeader,
  isResponse2XX,
  responseErrorHandler,
  getDataFromResponse
}
