import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';
import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMapMarkerAlt} from '@fortawesome/free-solid-svg-icons/faMapMarkerAlt';
import {COLOR_SECONDARY, COLOR_TEXT_DARK} from '../constants/styles';
import TextWrapper from './theme/TextWrapper';
import {faAngleRight} from '@fortawesome/free-solid-svg-icons/faAngleRight';

const Card = styled(ViewWrapper)`
  background-color: white;
  border-bottom-style: solid;
  border-color: #EAEAEA;
  padding: 10px 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
`;


function ListCard(props) {
  const onClick = props.onClick;
  return (
      <Card {...props}>
        {onClick && <ClickArrow/>}
        {props.children}
      </Card>
  )
}

function ClickArrow() {
  return (
      <ViewWrapper style={{position: "absolute", right: "10px"}}>
        <FontAwesomeIcon icon={faAngleRight} color={"#EAEAEA"} size={"lg"}/>
      </ViewWrapper>
  )
}

function Detail(props: {icon: string, color?: string}) {
  const icon = props.icon;
  const color = props.color || COLOR_TEXT_DARK;
  return (
      <ViewWrapper style={{display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", paddingTop: "5px", paddingBottom: "15px"}}>
        {icon && <FontAwesomeIcon icon={icon} color={color} size={"sm"} width={"20px"} style={{textAlign: "center", alignItems: "center"}}/>}
        <TextWrapper style={{fontSize: "0.8em", marginLeft: "15px"}}>
          {props.children}
        </TextWrapper>
      </ViewWrapper>
  )
}

ListCard.Detail = Detail;
export default ListCard;

