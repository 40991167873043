import React from 'react';
import ViewWrapper from '../theme/ViewWrapper';
import styled from 'styled-components';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons/faSearch';
import {COLOR_TEXT_DARK, COLOR_TEXT_LIGHT} from '../../constants/styles';

const Input = styled.select`
  background-color: transparent;
  border: none;
  flex: 1;
  color: ${COLOR_TEXT_LIGHT};
`;

const InputWrapper = styled(ViewWrapper)`
  background-color: transparent;
  color: ${props => props.color || COLOR_TEXT_LIGHT};
  border-radius: 50px;
  border-style: solid;
  border-color: ${props => props.color || COLOR_TEXT_LIGHT};
  border-width: 1px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

type Props = {
  color: string,
  placeholder: string,
  onChange: () => void,
}

function SelectInput(props: Props) {
  return (
      <InputWrapper {...props}>
        <Input {...props} style={{textTransform: 'capitalize'}}/>
      </InputWrapper>
  )
}

export default SelectInput;