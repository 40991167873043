import React, {useEffect, useState} from 'react';
import ViewWithHeading, {AdditionalHeadingView} from '../../components/ViewWithHeading';
import BookingPanelDetail, {
  BookingPanel,
  BookingPanelDetailTitle,
} from '../bookings/BookingPanelDetail';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';
import SelectInput from '../../components/input/SelectInput';
import map from 'lodash/map';
import InfoAlert from '../../components/alerts/InfoAlert';
import ViewWrapper from '../../components/theme/ViewWrapper';
import AccentButton from '../../components/buttons/AccentButton';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useHistory, useLocation} from 'react-router';
import routes from '../../constants/routes';
import MosquePrayerSlotsGenericView from './MosquePrayerSlotsGenericView';
import {SALAH_SLOTS} from '../../config/appConfig';
import type {SalahSlotType} from '../../config/appConfig';

function DefaultSlotsView() {
  const history = useHistory();

  const [type: SalahSlotType, setType] = useState(null);

  const queryParams = new URLSearchParams(useLocation().search)

  useEffect(() => {
    if (queryParams) {
      onSalahTypeChange(queryParams.get("salah"))
    }
  }, [queryParams])

  function onSalahTypeChange(salah) {
    const index = SALAH_SLOTS.findIndex(el => el.salah === salah)
    if (index > -1) {
      setType(SALAH_SLOTS[index])
    }
  }

  return (
        <ViewWithHeading title={"Add New Slot"}>
          <AdditionalHeadingView>
            <BookingPanel>
              <BookingPanelDetail icon={faMosque} isDark={true}>
                <BookingPanelDetailTitle>
                  Select Salah
                </BookingPanelDetailTitle>
              </BookingPanelDetail>
              <SelectInput onChange={(event) => onSalahTypeChange(event.target.value)} value={type?.salah}>
                <option value={null}>Select Salah</option>
                {map(SALAH_SLOTS, (salahSlot) => <option key={salahSlot.salah} value={salahSlot.salah}>{salahSlot.salah}</option>)}
              </SelectInput>
            </BookingPanel>
          </AdditionalHeadingView>
          {type ?
              <>
                <MosquePrayerSlotsGenericView salahSlotType={type}/>
                <ViewWrapper style={{
                  position: 'fixed',
                  bottom: '60pxp',
                  fontSize: '0.9em',
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  zIndex: 100,
                }}>
                  <AccentButton
                      onClick={() => history.push(routes.SLOTS_BASE)}
                      style={{
                        fontSize: '0.9em',
                        width: 'max-content'
                      }}
                  >
                    <FontAwesomeIcon icon={faTimes}
                                     style={{marginRight: '15px'}}/>
                    Go Back
                  </AccentButton>
                </ViewWrapper>
              </>
              :
              <InfoAlert>Please select a Salah above...</InfoAlert>
          }
        </ViewWithHeading>
  )
}

export default DefaultSlotsView;
