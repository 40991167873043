class MosqueNewRequest {
  name: string;
  url: string;
  address: string;
  email: string;
  phone: string;
  charity_registration_number: string;
  trustee_name_1: string;
  trustee_phone_1: string;
  trustee_email_1: string;
  trustee_name_2: string;
  trustee_phone_2: string;
  trustee_email_2: string;
  notes: string;

  constructor(
    name: string, url: string, address: string, email: string, phone: string, charity_registration_number: string,
    trustee_name_1: string, trustee_phone_1: string, trustee_email_1: string, trustee_name_2: string, trustee_phone_2: string,
    trustee_email_2: string, notes: string) {
    this.name = name;
    this.url = url;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.charity_registration_number = charity_registration_number;
    this.trustee_name_1 = trustee_name_1;
    this.trustee_phone_1 = trustee_phone_1;
    this.trustee_email_1 = trustee_email_1;
    this.trustee_name_2 = trustee_name_2;
    this.trustee_phone_2 = trustee_phone_2;
    this.trustee_email_2 = trustee_email_2;
    this.notes = notes;
  }
}

export default MosqueNewRequest;