import React, { Fragment } from 'react';
import ViewWrapper from '../theme/ViewWrapper';
import styled from 'styled-components';
import TextWrapper from '../theme/TextWrapper';
import {COLOR_ERROR, COLOR_TEXT_DARK} from '../../constants/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo } from '@fortawesome/free-solid-svg-icons';

function InfoAlert(props) {
  return (
    <Fragment>
      <AlertView {...props}>
        <FontAwesomeIcon
          icon={faInfo}
          color={COLOR_TEXT_DARK}
          style={{ width: '20px', marginRight: '15px' }}
        />
        <AlertText>{props.children}</AlertText>
      </AlertView>
    </Fragment>
  );
}

const AlertView = styled(ViewWrapper)`
  background-color: white;
  border-color: white;
  border-radius: 10px;
  border-style: solid;
  padding: 8px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 8px 10px 15px 10px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
  min-height: max-content;
`;

const AlertText = styled(TextWrapper)`
  font-size: 1em;
  font-weight: 700;
  color: ${COLOR_TEXT_DARK};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export default InfoAlert;
