import MosqueBookingWithSlot from './MosqueBookingWithSlot';
import Slot from './Slot';
import User from './User';

class VerificationResponse {
  booking_id: string;
  date: Date;
  num_people_female: number;
  num_people_male: number;
  slot: Slot;
  user: User;
  verified_time: string;
  already_verified: boolean;

  constructor(
      booking_id: string, date: Date, num_people_female: number,
      num_people_male: number, slot: Slot, user: User, verified_time: string,
      already_verified: boolean) {
    this.booking_id = booking_id;
    this.date = date;
    this.num_people_female = num_people_female;
    this.num_people_male = num_people_male;
    this.slot = slot;
    this.user = user;
    this.verified_time = verified_time;
    this.already_verified = already_verified;
  }
}

export default VerificationResponse;