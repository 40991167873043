class SlotRequest {
  name: string;
  timing: {
    open: Date,
    start: Date,
  };
  salah: string;
  capacity_male: number;
  capacity_female: number;

  constructor(
      name: string, timing: { open: Date, start: Date }, salah: string,
      capacity_male: number, capacity_female: number) {
    this.name = name;
    this.timing = timing;
    this.salah = salah;
    this.capacity_male = capacity_male;
    this.capacity_female = capacity_female;
  }
}

export default SlotRequest;