import React, { Fragment, useContext, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import styled from 'styled-components';
import TitleText from '../../../components/text/TitleText';
import Form from 'react-bootstrap/Form';
import AccentButton from '../../../components/buttons/AccentButton';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { AuthContext } from '../../../providers/AuthProvider';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import SuccessAlert from '../../../components/alerts/SuccessAlert';
import HeadingText from '../../../components/text/HeadingText';

function EmailPasswordResetView() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isUserCreted, setIsUserCreated] = useState(false);

  const authContext = useContext(AuthContext);

  function onSubmit() {
    if (password !== confirmPassword) {
      setError('Passwords must match');
    } else {
      setLoading(true);
      setError(null);
      authContext
        .doCreateUserWithEmailAndPassword(email, password)
        .then(() => setIsUserCreated(true))
        .catch((e) => setError(e.message))
        .finally(() => setLoading(false));
    }
  }

  function renderElements() {
    if (loading) {
      return <LoadingCircles>Signing you in...</LoadingCircles>;
    } else if (isUserCreted) {
      return <LoadingCircles>Authenticating you...</LoadingCircles>;
    } else {
      return (
        <Fragment>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          <FormView>
            <FormInput>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                size={'lg'}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter your password'
                size={'lg'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <Form.Label>Confirm password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Confirm your password'
                size={'lg'}
                value={confirmPassword}
                onChange={(event) => setConfirmPassword(event.target.value)}
              />
            </FormInput>
          </FormView>
          <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>
        </Fragment>
      );
    }
  }

  return (
    <View>
      <HeadingText>Sign up With Email</HeadingText>
      {renderElements()}
    </View>
  );
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const FormView = styled(ViewWrapper)`
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default EmailPasswordResetView;
