import React from 'react';
import styled from 'styled-components';
import BaseButton from './BaseButton';

const SecondaryButton = styled(BaseButton)`
  border-radius: 10px;
  border-color: white;
  border-style: solid;
  background-color: transparent;
  color: white;
`;

export default SecondaryButton;