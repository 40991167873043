import { APP_ENVIRONMENT } from './appConfig';

let FIREBASE_CONFIG;

if (process.env.NODE_ENV === 'production' && APP_ENVIRONMENT === 'production') {
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBfJ3bJpVWcZUWq0wGBQSfuvWVBdVIF22Y',
    authDomain: 'salah-space.firebaseapp.com',
    databaseURL: 'https://salah-space.firebaseio.com',
    projectId: 'salah-space',
    storageBucket: 'salah-space.appspot.com',
    messagingSenderId: '524784151993',
    appId: '1:524784151993:web:4ae7563ff0239b546b5a68',
    measurementId: 'G-BGDVER6C26',
  };
} else {
  FIREBASE_CONFIG = {
    apiKey: 'AIzaSyBUCAUWifDY0JfCrEHMKgWgrsgzhgd2UCU',
    authDomain: 'salah-space-dev.firebaseapp.com',
    databaseURL: 'https://salah-space-dev.firebaseio.com',
    projectId: 'salah-space-dev',
    storageBucket: 'salah-space-dev.appspot.com',
    messagingSenderId: '789513784141',
    appId: '1:789513784141:web:1031cf06f9051d03868504',
    measurementId: 'G-56Y9B2Z4C1',
  };
}

export { FIREBASE_CONFIG };
