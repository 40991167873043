import React, { Fragment, useContext, useState } from 'react';
import ResponsiveView from '../../../components/ResponsiveView';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import { MosquesForm } from './MosquesForm';
import ViewWithHeading, {
  AdditionalHeadingView,
} from '../../../components/ViewWithHeading';
import mosquesService from '../../../services/mosquesService';
import MosqueNewRequest from '../../../models/MosqueNewRequest';
import { AuthContext } from '../../../providers/AuthProvider';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import BottomCardView from '../../../components/BottomCardView';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import onboardingLogo from '../../../assets/undraw_onboarding_o8mv.svg'
import styled from 'styled-components';

function MosqueSignUpView() {
  const authContext = useContext(AuthContext);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleFormSubmission = (data) => {
    setLoading(true);
    setError(null);
    const mosqueModel = new MosqueNewRequest(
      data.name,
      data.url,
      data.address + ',' + data.postcode,
      data.email,
      data.phone,
      data.charity_registration_number,
      data.trustee_name_1,
      data.trustee_phone_1,
      data.trustee_email_1,
      data.trustee_name_2,
      data.trustee_phone_2,
      data.trustee_email_2,
      data.notes
    );
    mosquesService.postMosque(mosqueModel).
      then((data) => authContext.setMosque(data)).
      catch((e) => setError(e.message)).
      finally(() => setLoading(false));
    window.location.reload();
  };

  function renderFormElements() {
    if (loading) {
      return <LoadingCircles>Registering your mosque...</LoadingCircles>;
    } else {
      return <MosquesForm onComplete={handleFormSubmission} />;
    }
  }

  return (
    <View>
      {error && <ErrorAlert>{error}</ErrorAlert>}
      <MosquesForm onComplete={handleFormSubmission} />
    </View>
  );
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;

export default MosqueSignUpView;
