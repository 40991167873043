import Slot from '../../models/Slot';
import BookingPanelDetail, {
  BookingPanel,
  BookingPanelDetailTitle,
} from './BookingPanelDetail';
import React, {useEffect, useState} from 'react';
import SelectInput from '../../components/input/SelectInput';
import styled from 'styled-components';
import ViewWrapper from '../../components/theme/ViewWrapper';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import size from 'lodash/size';

function SlotSelection(props: { slots: Array<Slot>, onChange: (slot: Slot) => void }) {

  const {slots, onChange} = {...props};

  const [defaultValue, setDefaultValue] = useState(null);

  useEffect(() => {
    if (size(slots) === 1) {
      setDefaultValue(slots[0].slot_id);
    }
  }, [slots]);

  useEffect(() => {
    onSelect(defaultValue);
  }, [defaultValue]);

  function onSelect(slotId) {
    const slot = find(slots, (slot) => slot.slot_id === slotId);
    onChange(slot);
  }

  return (
      <BookingPanel>
        <BookingPanelDetail icon={faClock} isDark={true}>
          <BookingPanelDetailTitle>
            Select Available Slot
          </BookingPanelDetailTitle>
        </BookingPanelDetail>
        <SelectInput onChange={(event) => onSelect(event.target.value)} value={defaultValue}>
          <option value={null}>Select Available Slot</option>
          {slots.map(slot => <option key={slot.slot_id}  value={slot.slot_id}>{slot.timing.start}</option>)}
        </SelectInput>
      </BookingPanel>
  );
}

const SlotTime = styled(ViewWrapper)`
  margin-right: 30px;
`;

export default SlotSelection;