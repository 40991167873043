import ViewWrapper from './theme/ViewWrapper';
import React, { Fragment, useContext } from 'react';
import { AuthContext } from '../providers/AuthProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  COLOR_PRIMARY,
  COLOR_TEXT_LIGHT,
} from '../constants/styles';
import styled from 'styled-components';
import { useHistory } from 'react-router';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import TextWrapper from './theme/TextWrapper';
import { APP_ENVIRONMENT } from '../config/appConfig';

type Props = {
  title: string,
};

function Header(props: Props) {
  const authContext = useContext(AuthContext);
  const history = useHistory();

  return (
    <Fragment>
      <TopHeader>
        {APP_ENVIRONMENT !== 'production' && (
          <TextWrapper
            style={{
              width: '100%',
              color: 'white',
              textTransform: 'uppercase',
              fontWeight: 800,
              textAlign: 'center',
              backgroundColor: '#59cb34',
            }}>
            {APP_ENVIRONMENT}
          </TextWrapper>
        )}
        <Title>{props.title}</Title>
      </TopHeader>
    </Fragment>
  );
}

const Title = styled(ViewWrapper)`
  color: white;
  font-weight: 800;
  font-size: 1.7em;
  padding-bottom: 7px;
  padding-left: 10px;
  z-index: 10;
  text-align: left;
`;

const TopHeader = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${COLOR_PRIMARY};
  min-height: max-content;
`;

export default Header;
