import {Redirect, Route, Switch} from 'react-router';
import React, {useContext} from 'react';
import routes from '../../constants/routes';
import MosqueDetailsView from './details/MosqueDetailsView';
import MosqueSignUpView from './mosquesignup/MosqueSignUpView';
import {AuthContext} from '../../providers/AuthProvider';

function MosqueRoutes(props) {
  const authContext = useContext(AuthContext);

  // if (!authContext.hasAddedMosque()) {
  //   return           <MosqueSignUpView />;
  // }

  return (
      <Switch>
        <Route exact path={routes.MOSQUE_DETAILS}>
          <MosqueDetailsView />
        </Route>
        {/*<Route exact path={routes.MOSQUE_SIGN_UP}>*/}
        {/*  <MosqueSignUpView />*/}
        {/*</Route>*/}
        <Redirect to={routes.MOSQUE_DETAILS}/>
      </Switch>
  )
}

export default MosqueRoutes;