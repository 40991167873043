import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import mosquePrayersService from '../../../services/mosquePrayersService';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import { faClock } from '@fortawesome/free-solid-svg-icons/faClock';
import TextWrapper from '../../../components/theme/TextWrapper';
import {
  DetailsInfoBlock,
  DetailsInfoTitle,
} from '../../../components/InformationDetailComponents';
import AccentButton from '../../../components/buttons/AccentButton';
import { useHistory } from 'react-router';
import routes from '../../../constants/routes';
import { ProgressBar, Table, Button } from 'react-bootstrap';
import styled from 'styled-components';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import InfoAlert from '../../../components/alerts/InfoAlert';

const NoSlotsAvailable = () => {
  const history = useHistory();
  return (
    <Fragment>
      <ErrorAlert>No Jumuah times available</ErrorAlert>
      <AccentButton onClick={() => history.push(routes.SLOTS_ADD_JUMUAH)}>
        Add Jumuah times
      </AccentButton>
    
      <ErrorAlert>No Eid times available</ErrorAlert>
      <AccentButton onClick={() => history.push(routes.SLOTS_ADD_EID)}>
        Add Eid times
      </AccentButton>
    </Fragment>
  );
};

const SlotDetailsItems = ({ slots, onSlotDeleteAction }) => (
  <DetailsInfoBlock>
    {slots.map((slot) => {
      const maleCapacityPercentage = (slot.available_male / slot.capacity_male) * 100;
      const maleCapacityPercentageVariant =
        maleCapacityPercentage < 25
          ? 'danger'
          : maleCapacityPercentage > 75
          ? 'success'
          : 'info';
      const femaleCapacityPercentage = (slot.available_female / slot.capacity_female) * 100;
      const femaleCapacityPercentageVariant =
        maleCapacityPercentage < 25
          ? 'danger'
          : maleCapacityPercentage > 75
          ? 'success'
          : 'info';
      const canBeModified = slot.available_male === slot.capacity_male && slot.available_female === slot.capacity_female;

      return (
        <Fragment key={slot.slot_id}>
          <DetailsInfoTitle icon={faClock} title={slot.name} />
          <Table striped responsive style={{ marginBottom: '50px' }}>
            <tbody>
              <tr>
                <th>Doors Open:</th>
                <td>{slot.timing.open}</td>
              </tr>
              <tr>
              <th>{slot.salah === 'eid' ? "Prayer Start:" : "Khutbah Start:"}</th>
                <td>{slot.timing.start}</td>
              </tr>
              <tr>
                <th>Male Capacity:</th>
                <td>{slot.capacity_male}</td>
              </tr>
              <tr>
                <th>Female Capacity:</th>
                <td>{slot.capacity_female}</td>
              </tr>
              <tr>
                <th>Male Capacity Remaining:</th>
                <td>
                  <ProgressBar
                    variant={maleCapacityPercentageVariant}
                    now={maleCapacityPercentage}
                    label={slot.available_male}
                  />
                </td>
              </tr>
              <tr>
                <th>Female Capacity Remaining:</th>
                <td>
                  <ProgressBar
                    variant={femaleCapacityPercentageVariant}
                    now={femaleCapacityPercentage}
                    label={slot.available_female}
                  />
                </td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td>
                  {/* <Button
                    variant='outline-info'
                    type='button'
                    size='md'
                    block
                    disabled={canBeModified}>
                    Update
                  </Button> */}
                </td>
                <td>
                  {canBeModified ?
                      <Button
                          variant='outline-danger'
                          type='button'
                          size='md'
                          block
                          onClick={() => onSlotDeleteAction(slot.slot_id)}>
                        Remove
                      </Button>

                      :
                      <InfoAlert>you cannot delete this slot as you already have bookings created.</InfoAlert>
                  }
                </td>
              </tr>
            </tfoot>
          </Table>
        </Fragment>
      );
    })}
  </DetailsInfoBlock>
);

SlotDetailsItems.propTypes = {
  slots: PropTypes.array.isRequired,
};

const AddMoreSlots = () => {
  const history = useHistory();
  return (
    <FormInput
      style={{
        width: '100%',
        paddingTop: '10px',
        borderTop: 'solid #e1e1e1 1px',
        marginTop: '10px',
      }}>
      <Button
        className="mr-3"
        style={{ fontStyle: 'underlined' }}
        onClick={() => history.push(routes.SLOTS_ADD_JUMUAH)}
        variant='secondary'>
        Add Jumuah times
      </Button>
      <Button
        style={{ fontStyle: 'underlined' }}
        onClick={() => history.push(routes.SLOTS_ADD_EID)}
        variant='secondary'>
        Add Eid times
      </Button>
    </FormInput>
  );
};

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const SlotDetails = ({ mosque }) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [slots, setSlots] = useState([]);

  useEffect(() => {
    setLoading(true);
    setError(null);

    if (mosque != null) {
      mosquePrayersService
        .getPrayerSlots()
        .then((data) => setSlots(data))
        .then(() => setLoading(false))
        .catch((e) => setError(e.message));
    }
  }, [mosque]);

  const onSlotDeleteAction = async (slot_id) => {
    // console.info('request for slot_id:', slot_id, 'to be deleted');
    const response = await mosquePrayersService.deletePrayerSlot(slot_id);

    // console.info('got response', response);

    // if (response.status === 'Deleted') {
    // console.info('updating slot from backend');
    // filter slots by slot_id
    // const updatedSlots = slots.filter((slot) => slot.slot_id !== slot_id);
    // setSlots(updatedSlots);

    // TODO: clean up this mess, do not pull from the backend
    mosquePrayersService
      .getPrayerSlots()
      .then((data) => setSlots(data))
      .then(() => setLoading(false))
      .catch((e) => setError(e.message));
    // }
  };

  return (
    <DetailsInfoBlock>
      {loading && <TextWrapper>Loading...</TextWrapper>}
      {error && <ErrorAlert>{error}</ErrorAlert>}
      {slots == null || slots.length === 0 ? (
        <NoSlotsAvailable />
      ) : (
        <Fragment>
          <SlotDetailsItems
            slots={slots}
            onSlotDeleteAction={onSlotDeleteAction}
          />
          <AddMoreSlots />
        </Fragment>
      )}
    </DetailsInfoBlock>
  );
};

SlotDetails.propTypes = {
  mosque: PropTypes.object.isRequired,
};

export default SlotDetails;
