import React, {useContext, useState} from 'react';
import ViewWrapper from '../../components/theme/ViewWrapper';
import styled from 'styled-components';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import LoadingCircles from '../../components/loading/LoadingCircles';
import BottomCardView from '../../components/BottomCardView';
import { useHistory } from 'react-router';
import mosquePrayersService from '../../services/mosquePrayersService';
import SlotRequest from '../../models/SlotRequest';
import SuccessAlert from '../../components/alerts/SuccessAlert';
import routes from '../../constants/routes';
import {MosquePrayerSlotsGenericForm} from './MosquePrayerSlotsGenericForm';
import type {SalahSlotType} from '../../config/appConfig';
import Slot from '../../models/Slot';
import isEmpty from 'lodash/isEmpty'

type Props = {
  salahSlotType: SalahSlotType,
  slot?: Slot
}
function MosquePrayerSlotsGenericView({salahSlotType, slot={}} : Props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [successMessage: string, setSuccessMessage] = useState(null);

  const isEdit = isEmpty(slot)

  const history = useHistory();

  const onComplete = (formData) => {
    console.info('onComplete slots', formData);
    const slots = formData.map(data => Object.assign(new SlotRequest(), data));
    mosquePrayersService.createPrayerSlot(slots)
        .then(() => setSuccessMessage("Successfully created prayer slots!"))
        .catch((e) => setError(e.message))
        .finally(() => setLoading(false));
  };

  function renderElements() {
    if (loading) {
      return <LoadingCircles>Signing you in...</LoadingCircles>;
    } else {
      return (
        <View>
          {error && (<ErrorAlert>{error}</ErrorAlert>)}
          {successMessage ? <SuccessAlert>{successMessage}</SuccessAlert>
          :
              <MosquePrayerSlotsGenericForm salahSlotType={salahSlotType} onComplete={onComplete} />
          }
        </View>
      );
    }
  }

  return (
      <BottomCardView style={{ justifyContent: 'flex-start', alignItems: 'center', flex: 1 }}>
        {renderElements()}
        <FormInput style={{ width: '100%', paddingTop: '10px', borderTop: "solid #e1e1e1 1px", marginTop: "10px"}}>
          <a href={"#"}
             style={{ fontStyle: 'underlined' }}
             onClick={() => history.push(routes.MOSQUE_DETAILS)}>
            Go to Mosque Details
          </a>
        </FormInput>
      </BottomCardView>
  );
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;


const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default MosquePrayerSlotsGenericView;
