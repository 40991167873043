import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';
import React from 'react';
import { MAX_MOBILE_WIDTH } from '../constants/styles';

const ResponsiveViewWrapper = styled(ViewWrapper)`
  justify-content: center;
  width: 100%;
  max-width: 600px;
`;

function ResponsiveView(props) {
  return (
    <ViewWrapper
      style={{
        display: 'flex',
        flex: 1,
        width: '100%',
        justifyContent: 'center',
      }}>
      <ResponsiveViewWrapper
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          alignItems: 'stretch',
          justifyContent: 'center'
        }} {...props}>
      </ResponsiveViewWrapper>
    </ViewWrapper>
  );
}

export default ResponsiveView;
