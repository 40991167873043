import axios from 'axios';
import Mosque from '../models/Mosque';
import {API_URL} from '../config/apiConfig';
import MosqueWithSlots from '../models/MosqueWithSlots';
import MosqueBooking from '../models/MosqueBooking';
import MosqueNewRequest from '../models/MosqueNewRequest';
import {
  getDataFromResponse,
  isResponse2XX,
  responseErrorHandler,
} from '../config/axiosConfig';
import Logger from '../Logger';

const MOSQUES_ENDPOINT_PATH = "/masjid";
const PUBLIC_MOSQUES_ENDPOINT_PATH = "/public/masjid";

class MosquesService {

  constructor() {
    this.logger = new Logger(MosquesService.name);
  }

  getMosquesByLocation = async (lat: number, lng: number) : Array<Mosque> => {
    this.logger.info("Fetching mosques by location", lat, lng);
    const response = await axios.get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + "/search", {
        params: {
          latitude: lat, longitude: lng
        }
      }).catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return data.map(obj => Object.assign(new Mosque(), obj));
  };

  getMosquesByPostcode = async (postcode: string) : Array<Mosque> => {
    this.logger.info("Fetching mosques by postcode", postcode);
    const response = await axios.get(API_URL + MOSQUES_ENDPOINT_PATH + "/search", {
      params: {
        postcode: postcode
      }
    }).catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return data.map(obj => Object.assign(new Mosque(), obj));
  };

  getMosquesById = async (mosqueId: string) : MosqueWithSlots => {
    this.logger.info("Fetching mosque by id", mosqueId);
    const response = await axios.get(API_URL + PUBLIC_MOSQUES_ENDPOINT_PATH + "/" + mosqueId)
        .catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueWithSlots(), data);
  };

  getMosquesForUser = async () : MosqueWithSlots => {
    this.logger.info("Fetching mosque for user");
    const response = await axios.get(API_URL + MOSQUES_ENDPOINT_PATH)
        .catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueWithSlots(), data)

  };

  postMosque = async (mosqueNewRequest: MosqueNewRequest) : MosqueNewRequest => {
    const response = await axios.post(API_URL + MOSQUES_ENDPOINT_PATH, mosqueNewRequest)
        .catch ((err) => responseErrorHandler(err));

    const data = getDataFromResponse(response);
    return Object.assign(new MosqueNewRequest(), data);
  };

}

export default new MosquesService();