import Slot from './Slot';

class MosqueWithSlots {
  id: string;
  name: string;
  address: string;
  location: {
    lat: number, lng: number
  };
  url: string;
  notes: string;
  slots: Array<Slot>;
  pending: string;
  slug: string;

  constructor(
      id: string, name: string, address: string,
      location: { lat: number, lng: number }, url: string, notes: string,
      slots: Array<Slot>, pending: string, slug: string) {
    this.id = id;
    this.name = name;
    this.address = address;
    this.location = location;
    this.url = url;
    this.notes = notes;
    this.slots = slots;
    this.pending = pending;
    this.slug = slug;
  }
}

export default MosqueWithSlots;