export const COLOR_PRIMARY = '#00a1ff';
export const COLOR_PRIMARY_LIGHT = '#7bc9f2';
export const COLOR_SECONDARY = '#E85E17';
export const COLOR_ACCENT = '#2969b0';
export const COLOR_ACCENT_LIGHT = '#019eae';
export const COLOR_ERROR = '#E83522';
export const COLOR_SUCCESS = '#35ca73';

export const COLOR_TEXT_DARK = '#3a3a3a';
export const COLOR_TEXT_LIGHT = '#FFFFFF';
export const COLOR_TEXT_DISABLED = '#7a7a7a';

// export const COLOR_GRADIENT_DEFAULT = ["#019eae", "#2969b0"];
export const COLOR_GRADIENT_DEFAULT = ['#ED5152', '#ED5152'];

export const MAX_MOBILE_WIDTH = '768px';
