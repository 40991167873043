import React, { useContext, useState } from 'react';
import ViewWrapper from '../../../components/theme/ViewWrapper';
import styled from 'styled-components';
import Form from 'react-bootstrap/Form';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { AuthContext } from '../../../providers/AuthProvider';
import ErrorAlert from '../../../components/alerts/ErrorAlert';
import LoadingCircles from '../../../components/loading/LoadingCircles';
import SuccessAlert from '../../../components/alerts/SuccessAlert';
import TextWrapper from '../../../components/theme/TextWrapper';
import ViewWithHeading, {AdditionalHeadingView} from '../../../components/ViewWithHeading';
import BottomCardView from '../../../components/BottomCardView';
import image from '../../../assets/undraw_inbox_cleanup.svg';
import routes from '../../../constants/routes';
import {useHistory} from 'react-router';

function EmailSignInView() {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [forgetPasswordLoading, setForgetPasswordLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);
  const [isForgetPasswordEmailSent, setIsForgetPasswordEmailSent] = useState(
    false
  );

  const authContext = useContext(AuthContext);
  const history = useHistory();

  function onSubmit() {
    setLoading(true);
    setError(null);
    authContext
      .doEmailSignIn(email, password)
      .then(() => setIsSignInSuccess(true))
      .catch((e) => setError(e.message))
      .finally(() => setLoading(false));
  }

  function onForgetPasswordClick() {
    setForgetPasswordLoading(true);
    setError(null);
    authContext
      .doEmailSendPasswordResetEmail(email)
      .then(() => setIsForgetPasswordEmailSent(true))
      .catch((e) => setError(e.message))
      .finally(() => setForgetPasswordLoading(false));
  }

  function renderElements() {
    if (loading) {
      return <LoadingCircles>Signing you in...</LoadingCircles>;
    } else if (isSignInSuccess) {
      return <LoadingCircles>Authenticating you...</LoadingCircles>;
    } else if (forgetPasswordLoading) {
      return <LoadingCircles>Sending password reset email...</LoadingCircles>;
    } else {
      return (
        <View>
          <img src={image} height={"auto"} width={"90%"} style={{maxWidth: "500px", marginTop: "-20vh"}}/>
          {error && <ErrorAlert>{error}</ErrorAlert>}
          {isForgetPasswordEmailSent && <SuccessAlert>{'A password reset email has been sent to you!'}</SuccessAlert>}
          <FormView>
            <FormInput>
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type='email'
                placeholder='Enter your email'
                size={'lg'}
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type='password'
                placeholder='Enter your password'
                size={'lg'}
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
            </FormInput>
            <FormInput style={{ width: '100%', paddingTop: '10px' }}>
              <a href={"#"}
                style={{ fontStyle: 'underlined' }}
                onClick={() => onForgetPasswordClick()}>
                I have forgotten my password
              </a>
            </FormInput>
          </FormView>
          <PrimaryButton onClick={onSubmit}>Submit</PrimaryButton>

          <FormInput style={{ width: '100%', paddingTop: '10px' }}>
            <a href={"#"}
               style={{ fontStyle: 'underlined' }}
               onClick={() => history.push(routes.LOGIN_EMAIL_SIGN_UP)}>
              I don't have an account
            </a>
          </FormInput>


          <FormInput style={{ width: '100%', paddingTop: '10px', borderTop: "solid #e1e1e1 1px", marginTop: "10px"}}>
            <a href={"#"}
               style={{ fontStyle: 'underlined' }}
               onClick={() => history.push(routes.LOGIN)}>
              Go home
            </a>
          </FormInput>

        </View>
      );
    }
  }

  return (
    <ViewWithHeading title={'Sign in With Email'}>
      <AdditionalHeadingView style={{paddingTop: "100px"}} />
      <BottomCardView style={{alignItems: "center", flex: 1}}>{renderElements()}</BottomCardView>
    </ViewWithHeading>
  );
}

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;

const FormInput = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

const FormView = styled(ViewWrapper)`
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;

export default EmailSignInView;
