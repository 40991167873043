import styled from 'styled-components';
import ViewWrapper from './theme/ViewWrapper';
import React from 'react';

function PageIndicators(props: {pages: number, currentPage: number}) {

  const {pages, currentPage} = {...props};
  let pageIndicators = [];

    for (let page = 0; page < pages; page++) {
      pageIndicators.push(<Circle key={page} active={page === currentPage}/>)
    }

  return (
      <PageInticatorBlock>
        {pageIndicators}
      </PageInticatorBlock>
  )
}

const PageInticatorBlock = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 20px;
`;

const Circle = styled(ViewWrapper)`
  width: 15px;
  height: 15px;
  border: 1px solid ${props => props.active ? 'white' : 'transparent'};
  border-radius: 50%;
  background-color: ${props => props.active ? 'white' : 'rgba(0,0,0,0.3)'};
  margin: 10px;
`;

export default PageIndicators;