import {faMale} from '@fortawesome/free-solid-svg-icons/faMale';
import {faFemale} from '@fortawesome/free-solid-svg-icons/faFemale';
import {faCalendar} from '@fortawesome/free-solid-svg-icons/faCalendar';
import moment from 'moment';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import {faPray} from '@fortawesome/free-solid-svg-icons/faPray';
import React from 'react';
import VerificationResponse from '../../models/VerificationResponse';
import styled from 'styled-components';
import ViewWrapper from '../../components/theme/ViewWrapper';
import {COLOR_PRIMARY, COLOR_TEXT_DARK} from '../../constants/styles';
import TextWrapper from '../../components/theme/TextWrapper';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import HeadingText from '../../components/text/HeadingText';
import Slot from '../../models/Slot';

function BookingDetails(props: {verificationResponse: VerificationResponse}) {

  const {verificationResponse} = {...props};
  const slot = verificationResponse.slot;
  return (
      <FormView>
        <BookingHeading>Additional Attendees</BookingHeading>
        {verificationResponse.num_people_male != null &&
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faMale} title={"Additional male attendees"} />
          <BookingDetailsInfo>{verificationResponse.num_people_male} males attending</BookingDetailsInfo>
        </BookingDetailsBlock>
        }
        {verificationResponse.num_people_female != null &&
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faFemale} title={"Additional female attendees"} />
          <BookingDetailsInfo>{verificationResponse.num_people_female} females attending</BookingDetailsInfo>
        </BookingDetailsBlock>
        }
        <hr />
        <BookingHeading>Booking Details</BookingHeading>
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faCalendar} title={"Date of Salah"} />
          <BookingDetailsInfo>{moment(verificationResponse.date).format("dddd Do MMMM YYYY") || "N/A"}</BookingDetailsInfo>
        </BookingDetailsBlock>
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faMosque} title={"Salah"} />
          <BookingDetailsInfo>{slot.name || "N/A"}</BookingDetailsInfo>
        </BookingDetailsBlock>
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faDoorOpen} title={"Door open time"} />
          <BookingDetailsInfo>{slot.timing.open || "N/A"}</BookingDetailsInfo>
        </BookingDetailsBlock>
        <BookingDetailsBlock>
          <BookingDetailIcon icon={faPray} title={"Khutbah start time"} />
          <BookingDetailsInfo>{slot.timing.start || "N/A"}</BookingDetailsInfo>
        </BookingDetailsBlock>
      </FormView>
  )
}

const BookingDetailsBlock = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${COLOR_TEXT_DARK};
  padding: 10px 0;
  width: 100%;
  font-size: 1em;
`;

const BookingDetailsInfo = styled(TextWrapper)`
  padding-left: 50px;
`;

const BookingDetailIconWrapper = styled(ViewWrapper)`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 10px;
  width: 100%;
  color: ${COLOR_TEXT_DARK};
  padding-bottom: 5px;
`;

const BookingDetailIcon = (props: { icon: any, title: string }) => {
  return (
      <BookingDetailIconWrapper>
        <FontAwesomeIcon icon={props.icon} color={COLOR_PRIMARY} style={{ width: "40px" }} />
        <TextWrapper style={{ paddingLeft: "10px", fontSize: "1.1em", fontWeight: 700 }}>{props.title}</TextWrapper>
      </BookingDetailIconWrapper>
  )
};

const BookingHeading = styled(HeadingText)`
  color: ${COLOR_TEXT_DARK}
`;


const FormView = styled(ViewWrapper)`
  color: ${COLOR_TEXT_DARK} !important;
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;


export default BookingDetails;