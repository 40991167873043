import React, { useContext, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import routes from './constants/routes';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ViewWrapper from './components/theme/ViewWrapper';
import { APP_COMING_SOON } from './config/appConfig';
import { AuthContext } from './providers/AuthProvider';
import LoginRouter from './views/login/LoginRouter';
import LoadingCircles from './components/loading/LoadingCircles';
import Dock, {DockItem} from './components/Dock';
import {faMosque} from '@fortawesome/free-solid-svg-icons/faMosque';
import MosqueRoutes from './views/mosque/MosqueRoutes';
import SlotsRoutes from './views/slots/SlotsRoutes';
import {useHistory, useLocation} from 'react-router';
import {faCamera} from '@fortawesome/free-solid-svg-icons/faCamera';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import MosqueScannerView from './views/bookings/MosqueScannerView';

function App() {
  const authContext = useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (APP_COMING_SOON) {
      window.location.href = 'https://about.salahspace.app';
    }
  });

  useEffect(() => {
    window.scrollTo(0,0);
  }, [location.pathname]);

  function renderRoutes() {
    if (authContext.loading) {
      return <LoadingCircles>Loading app...</LoadingCircles>
    } else if (authContext.isLoggedIn()) {
      return (
          <ViewWrapper style={{display: 'flex', flexDirection: 'column', flex:1, overflowY: 'scroll', marginBottom: '55px'}}>
            <Switch>
              <Route path={routes.MOSQUE_BASE}>
                <MosqueRoutes/>
              </Route>
              <Route path={routes.SLOTS_BASE}>
                <SlotsRoutes />
              </Route>
              <Route path={routes.BOOKINGS_VERIFY}>
                <MosqueScannerView />
              </Route>
              <Redirect to={routes.MOSQUE_BASE} />
            </Switch>
          </ViewWrapper>
      )
    } else {
      return (
          <ViewWrapper style={{display: 'flex', flexDirection: 'column', flex:1, overflowY: 'scroll', marginBottom: '55px'}}>
            <Switch>
              <Route path={routes.LOGIN}>
                <LoginRouter />
              </Route>
              <Redirect to={routes.LOGIN} push={true} />
            </Switch>
          </ViewWrapper>
      )
    }
  }

  return (
      <ViewWrapper
          style={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            height: '100%',
            backgroundColor: 'white',
            minHeight: window.innerHeight,
          }}>
        {renderRoutes()}
        <Dock>
          <DockItem icon={faMosque} label={"My Mosque"} selected={location.pathname.startsWith(routes.MOSQUE_BASE)} onClick={() => history.push(routes.MOSQUE_BASE)}/>
          <DockItem icon={faCamera} label={"Scan Bookings"} selected={location.pathname.startsWith(routes.BOOKINGS_VERIFY)} onClick={() => history.push(routes.BOOKINGS_VERIFY)}/>
          <DockItem icon={faClock} label={"Salah Slots"} selected={location.pathname.startsWith(routes.SLOTS_BASE)} onClick={() => history.push(routes.SLOTS_BASE)}/>
        </Dock>
      </ViewWrapper>
  );
}

export default App;
