export default {
  LOGIN: '/login',
  LOGIN_EMAIL: '/login/email',
  LOGIN_EMAIL_SIGN_UP: '/login/email/sign-up',
  LOGIN_EMAIL_PASSWORD_RESET: '/auth/email-password-reset',
  LOGOUT: '/logout',

  HOME: '/',
  MOSQUE_BASE: '/mosque',
  MOSQUE_SIGN_UP: '/mosque/sign-up',
  MOSQUE_DETAILS: '/mosque/details',

  BOOKINGS_VERIFY: '/bookings/verify',

  QR_READER: '/qr',

  ADD_MOSQUE: '/add-mosque',
  SLOTS_BASE: '/slot',
  SLOTS_ADD: '/slot/add',
  SLOTS_ADD_JUMUAH: '/slot/add-jumuah',
  SLOTS_ADD_EID: '/slot/add-eid',

  ACCOUNT_BASE: '/account',
  ACCOUNT_DETAILS: '/account/details',


  //Temporary
  ADD_EID_SLOTS: '/add-eid-slots',
};
