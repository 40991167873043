import React, { Fragment } from 'react';
import styled from 'styled-components';
import TextWrapper from '../theme/TextWrapper';
import {
  COLOR_PRIMARY,
  COLOR_PRIMARY_LIGHT,
  MAX_MOBILE_WIDTH,
} from '../../constants/styles';

const BaseButton = styled.button`
  padding: ${props => props.small ? '7px 4px' : '10px 6px'};
  width: inherit;
  min-width: max-content;
  margin: ${props => props.small ? '0px 5px 0 5px' : '10px 5px 5px 5px'};
  min-height: ${props => props.small ? '30px' : '45px'};
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 5px;
  font-size: ${props => props.small ? '0.9em' : '1.2em'};
  font-weight: 800;
`;

export default BaseButton;
