import React, { Fragment, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { AuthContext } from '../../providers/AuthProvider';
import ErrorAlert from '../../components/alerts/ErrorAlert';
import LoadingCircles from '../../components/loading/LoadingCircles';
import ViewWithHeading, { AdditionalHeadingView } from '../../components/ViewWithHeading';
import BottomCardView from '../../components/BottomCardView';
import { useHistory } from 'react-router';
import ViewWrapper from '../../components/theme/ViewWrapper';
import QrReader from 'react-qr-reader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import verifyBookingService from '../../services/verifyBookingService';
import VerificationResponse from '../../models/VerificationResponse';
import TextWrapper from '../../components/theme/TextWrapper';
import {
  COLOR_ERROR,
  COLOR_SUCCESS,
  COLOR_TEXT_DARK,
} from '../../constants/styles';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import AccentButton from '../../components/buttons/AccentButton';
import InfoAlert from '../../components/alerts/InfoAlert';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons/faTimesCircle';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { Form} from 'react-bootstrap';
import SubheadingText from '../../components/text/SubheadingText';
import PrayerTypeSelection from './PrayerTypeSelection';
import BookingDetails from './BookingDetails';
import SlotSelection from './SlotSelection';
import Slot from '../../models/Slot';

function MosqueScannerView() {
  const [error, setError] = useState(null);
  const [info, setInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [verificationResponse: VerificationResponse, setVerificationResponse] = useState({});
  const [hasScanned, setHasScanned] = useState(false);

  const [slots, setSlots] = useState([]);

  const [slot: Slot, setSlot] = useState(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [bookingCode, setBookingCode] = useState(null);

  const authContext = useContext(AuthContext);
  const history = useHistory();


  // const mosque = authContext.mosque;
  function reset() {
    setError(null);
    setLoading(false);
    setVerificationResponse(null);
    setHasScanned(false)
    setInfo(null)
  }

  function submitBookingCode() {
    verifyBookingService.postVerifyCodeBooking(bookingCode, slot.slot_id).
      then((data) => setVerificationResponse(data)).
      catch((e) => setError(e.message)).
      finally(() => {
        setLoading(false);
      })
      ;
  }


  function onQrScanError(err) {
    setError(err.message);
  }

  function onQrScanSuccess(scanData) {
    if (!hasScanned) {
      if (scanData == null) {
        setInfo("Looking for QR code...")
      } else {
        setInfo("Click 'Scan another' to scan again.");
        setHasScanned(true);
        setLoading(true);
        setError(null);
        verifyBookingService.postVerifyQRBooking(scanData, slot.slot_id).
          then((data) => setVerificationResponse(data)).
          catch((e) => setError(e.message)).
          finally(() => setLoading(false))
          ;
      }
    }
  }

  function onBookingNumberChange(event) {
    let value = event.target.value;
    setBookingCode(value)
  }

  function isVerificationResponseValid() {
    return verificationResponse != null && verificationResponse.verified_time != null
  }

  function showBookingDetails() {
    return (
      <Fragment>
        <BookingDetails verificationResponse={verificationResponse} />
        {showScanButton()}
      </Fragment>
    )
  }

  function showScanButton() {
    let scanButtonText = 'Scan another';
    if (loading) {
      scanButtonText = 'Cancel'
    } else if (modalShow) {
      scanButtonText = 'Enter another booking number';
    } else if (hasScanned) {
      scanButtonText = 'Scan another';
    } else {
      scanButtonText = 'Reset scan';
    }
    return (
      <Buttons>
        {error && !modalShow && <PrimaryButton onClick={() => window.location.reload()}>{"Refresh"}</PrimaryButton>}
        <AccentButton onClick={reset}>{scanButtonText}</AccentButton>
      </Buttons>
    )
  }

  function renderElements() {
    if (loading) {
      return <LoadingCircles>Verifying booking...</LoadingCircles>;
    } else if(slots == null || slots.length === 0) {
      return <InfoAlert>Please select Salah above</InfoAlert>
    } else if(slot == null) {
      return <InfoAlert>Please select a time above</InfoAlert>
    } else {
      return (
          <Fragment>
            {renderScanOptions()}
            {isVerificationResponseValid() && showBookingDetails()}
          </Fragment>
      )
    }
  }

  function renderScanOptions() {
    if (modalShow) {
      return renderBookingNumber();
    } else {
      return (
          <Fragment>
            <WhiteBox>
              {isVerificationResponseValid() ?
                  <Fragment>
                    <FontAwesomeIcon icon={faCheckCircle} color={COLOR_SUCCESS} size={"4x"} />
                    {verificationResponse.already_verified && <InfoAlert>{"This booking has already been verified."}</InfoAlert>}
                  </Fragment>
                  :
                  hasScanned ?
                      <Fragment>
                        <FontAwesomeIcon icon={faTimesCircle} color={COLOR_ERROR} size={"4x"} />
                        {error && <ErrorAlert>{error}</ErrorAlert>}
                        {renderBookingNumber()}
                      </Fragment>
                      :
                      <Fragment>
                        {error &&
                        <Fragment>
                          <ErrorAlert>
                            {error}
                            <br />
                            Please try your default browser
                          </ErrorAlert>
                          {/*<Form.File />*/}
                        </Fragment>
                        }
                        <QrReader
                            delay={300}
                            onError={onQrScanError}
                            onScan={onQrScanSuccess}
                            style={{ width: '100%' }}
                            facingMode={"environment"}
                            legacyMode={error}
                        />
                      </Fragment>
              }
              {<TextWrapper>{info}</TextWrapper>}
            </WhiteBox>

            {showScanButton()}
          </Fragment>
      )
    }
  }

  function renderBookingNumber() {
    return (
        <ViewWrapper style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          alignItems: "center",
          width: "100%",
          height: "max-content"
        }}>
          {isVerificationResponseValid() ?
              <WhiteBox>
                <FontAwesomeIcon icon={faCheckCircle} color={COLOR_SUCCESS} size={"4x"} />
                {verificationResponse.already_verified && <InfoAlert>{"This booking has already been verified."}</InfoAlert>}
                {showScanButton()}
              </WhiteBox>
              :
              <Fragment>
                {error && <ErrorAlert>{error}</ErrorAlert>}
                <SubheadingText isDark={true}>Enter Booking Number</SubheadingText>
                <ViewWrapper style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}>
                  <Form.Control type="text"  size={'lg'} name="name" placeholder={"Enter Booking Number"} onChange={onBookingNumberChange} />
                </ViewWrapper>
                <PrimaryButton onClick={submitBookingCode}>Submit</PrimaryButton>
              </Fragment>
          }
        </ViewWrapper>
    )
  }

  return (
    <ViewWithHeading title={'Verify Bookings'}>
      <AdditionalHeadingView style={{ paddingTop: "10px", }}>
        <PrayerTypeSelection slots={authContext.slots} onChange={setSlots}/>
        <SlotSelection slots={slots} onChange={setSlot}/>
      </AdditionalHeadingView>
      <BottomCardView style={{alignItems: "center", flex: 1}}>
        {renderElements()}
      </BottomCardView>
      <ViewWrapper style={{paddingTop: "20px"}}/>
      <PrimaryButton onClick={() => setModalShow(!modalShow)}>{modalShow ? 'Scan QR Code' : 'Enter Booking Number'}</PrimaryButton>

    </ViewWithHeading>
  );
}

const SmallText = styled(TextWrapper)`
  color: ${COLOR_TEXT_DARK};
  text-align: center;
  font-size: 0.9em;
`;

const View = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
`;

const Buttons = styled(ViewWrapper)`
  width: 100%;
  padding: 8px 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const FormView = styled(ViewWrapper)`
  color: ${COLOR_TEXT_DARK} !important;
  width: 100%;
  padding: 10px 0;
  &:last-of-type {
    padding-bottom: 30px;
  }
`;



const WhiteBox = styled(ViewWrapper)`
  background-color: white;
  border: white solid;
  padding: 10px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  width: 80%;
  height: auto;
  max-width: 800px;
  max-height: 800px;
  box-shadow: rgba(0, 0, 0, 0.3) 0 0 10px;
`;

export default MosqueScannerView;
