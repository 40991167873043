class Slot {
  slot_id: string;
  name: string;
  timing: {
    open: Date,
    start: Date,
  };
  capacity_male: number;
  capacity_female: number;
  available_female: number;
  available_male: number;
  salah: string;

  constructor(
      slot_id: string, name: string, timing: { open: Date, start: Date },
      capacity_male: number, capacity_female: number, available_female: number,
      available_male: number, salah: string) {
    this.slot_id = slot_id;
    this.name = name;
    this.timing = timing;
    this.capacity_male = capacity_male;
    this.capacity_female = capacity_female;
    this.available_female = available_female;
    this.available_male = available_male;
    this.salah = salah;
  }
}

export default Slot;