import React, { useState, useEffect } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import { Form, Col, Button } from 'react-bootstrap';
import InfoAlert from '../../components/alerts/InfoAlert';
import { TimeDataListEid, TimeDataListJumuah, TimeDataListIsha } from '../../components/TimeDataList';
import type { SalahSlotType } from '../../config/appConfig';
import isEmpty from 'lodash/isEmpty'
import Slot from '../../models/Slot';

const convertTime12to24 = (time12h) => {
  const [time, modifier] = time12h.split(' ');
  let [hours, minutes] = time.split(':');

  if (hours === '12') {
    hours = '00';
  }

  if (modifier && modifier.toUpperCase() === 'PM') {
    hours = (parseInt(hours, 10) + 12).toString().padStart(2, '0');
  }

  return `${hours}:${minutes}`;
};

const schema = yup.object().shape({
  slots: yup
    .array()
    .of(
      yup.object().shape({
        name: yup.string().required('Prayer Name is required'),
        salah: yup.string(),
        timing: yup.object().shape({
          open: yup
            .string()
            .required()
            .transform(function (value, originalValue) {
              return this.isType(value) && value !== null
                ? convertTime12to24(value)
                : value;
            }),
          start: yup
            .string()
            .required()
            .transform(function (value, originalValue) {
              return this.isType(value) && value !== null
                ? convertTime12to24(value)
                : value;
            }),
        }),
        capacity_male: yup
          .number()
          .default(() => 0)
          .required(),
        capacity_female: yup
          .number()
          .default(() => 0)
          .required(),
      })
    )
    .required('You must agree before submitting'),
});

type Props = {
  salahSlotType: SalahSlotType,
  onComplete: () => {},
  slot?: Slot
};

export const MosquePrayerSlotsGenericForm = ({ salahSlotType = {}, onComplete = async () => { }, slot = {}, }: Props) => {
  const [indexes, setIndexes] = useState([0]);
  const [counter, setCounter] = useState(1);
  const [showRemove, setShowRemove] = useState(false);

  const isEdit = !isEmpty(slot)

  const { register, handleSubmit, errors, setValue } = useForm({
    resolver: yupResolver(schema),
    defaultValues: [slot]
  });

  useEffect(() => {
    if (!isEmpty(slot)) {
      // setValue("slots[0]", ...slot)
      console.log("updating slot form")
      setValue("slots[0].salah", salahSlotType.salah)
    }

  }, [slot])


  const onSubmit = async (data) => {
    // return the data back to the parent component to handle next
    console.log("on submit")
    if (isEdit) {
      console.log("on submit edit")
      await onComplete(data.slots[0]);
    } else {
      console.log("on submit not edit")
      await onComplete(data.slots);
    }
  };

  const addPrayer = () => {
    setIndexes((prevIndexes) => [...prevIndexes, counter]);
    setCounter((prevCounter) => prevCounter + 1);
  };

  const removePrayer = (index) => () => {
    setIndexes((prevIndexes) => [
      ...prevIndexes.filter((item) => item !== index),
    ]);
    setCounter((prevCounter) => prevCounter - 1);
  };

  useEffect(() => {
    if (indexes.length > 1) {
      setShowRemove(true);
    } else {
      setShowRemove(false);
    }
  }, [indexes]);

  console.log(errors);

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {salahSlotType.salah == 'eid' ? <TimeDataListEid id='time-data-list' /> : salahSlotType.salah == 'jumuah' ? <TimeDataListJumuah id='time-data-list' /> : <TimeDataListIsha id='time-data-list' />}
      <InfoAlert>
        Please note that you will be able to edit salah slots but this will
        effect all existing bookings.
        <br />
        Please remember to reserve some spaces for the elderly or those who may
        not have access to Salah Space.
      </InfoAlert>
      {/* <InfoAlert>
        Please note that prayer slots details can only be added and deleted
        until Monday 11:59 pm. Reservations starts Tuesday 12:00am morning.
        <br />
        Please remember to reserve some spaces for the elderly or those who may
        not have access to Salah Space.
      </InfoAlert> */}
      {indexes.map((index) => {
        const fieldName = `slots[${index}]`;
        return (
          <Form.Row
            key={index}
            style={{
              marginTop: '15px',
              marginBottom: '20px',
              borderBottom: 'solid #e1e1e1 1px',
            }}>
            <Form.Group controlId={`${fieldName}.salah`} as={Col} xs={12} style={{ marginTop: '-15px', marginBottom: '-20px' }}>
              <Form.Control
                type="hidden"
                name={`${fieldName}.salah`}
                ref={register}
                value={salahSlotType.salah}
                disabled
              />
            </Form.Group>
            <Form.Group controlId={`${fieldName}.name`} as={Col} xs={12}>
              <Form.Label>{salahSlotType.salah?.toUpperCase()}</Form.Label>
              <Form.Control
                name={`${fieldName}.name`}
                placeholder={salahSlotType.salah == 'eid' ? 'Eid Salah 1, Eid Salah 2 etc.' : salahSlotType.salah == 'jumuah' ? 'Jumuah Salah 1, Jumuah Salah 2 etc.' : 'Isha, Taraweeh etc.'}
                ref={register}
                isInvalid={errors?.slots?.[index]?.name}
              />
              <ErrorMessage
                errors={errors}
                name={`${fieldName}.name`}
                as={Form.Control.Feedback}
                type='invalid'
              />
            </Form.Group>

            <Form.Group
              controlId={`${fieldName}.timing.open`}
              as={Col}
              xs={6}
              md={3}>
              <Form.Label>Door Opens</Form.Label>
              <Form.Control
                name={`${fieldName}.timing.open`}
                ref={register}
                isInvalid={errors?.slots?.[index]?.timing?.open}
                placeholder={salahSlotType.salah == 'eid' ? '10:00 am' : salahSlotType.salah == 'jumuah' ? '01:00 pm' : '08:30 pm'}
                list='time-data-list'
                title='Time must be formatted as hh:mm am/pm'
              />
            </Form.Group>

            <Form.Group
              controlId={`${fieldName}.timing.start`}
              as={Col}
              xs={6}
              md={3}>
              <Form.Label>{salahSlotType.startLabel} Starts</Form.Label>
              <Form.Control
                name={`${fieldName}.timing.start`}
                ref={register}
                isInvalid={errors?.slots?.[index]?.timing?.start}
                placeholder={salahSlotType.salah == 'eid' ? '10:30 am' : salahSlotType.salah == 'jumuah' ? '01:15 pm' : '09:00 pm'}
                list='time-data-list'
                title='Time must be formatted as hh:mm am/pm'
              />
            </Form.Group>

            <Form.Group
              controlId={`${fieldName}.capacity_male`}
              as={Col}
              xs={6}
              md={3}>
              <Form.Label>Male Capacity</Form.Label>
              <Form.Control
                type='number'
                min={0}
                name={`${fieldName}.capacity_male`}
                placeholder='Male Capacity'
                ref={register}
                isInvalid={errors?.slots?.[index]?.capacity_male}
              />
            </Form.Group>

            <Form.Group
              controlId={`${fieldName}.capacity_female`}
              as={Col}
              xs={6}
              md={3}>
              <Form.Label>Female Capacity</Form.Label>
              <Form.Control
                type='number'
                min={0}
                name={`${fieldName}.capacity_female`}
                placeholder='Female Capacity'
                ref={register}
                isInvalid={errors?.slots?.[index]?.capacity_female}
              />
            </Form.Group>

            {showRemove && (
              <Form.Group
                className='text-right'
                style={{ marginTop: '10px', marginBottom: '30px' }}>
                <Button
                  variant='outline-danger'
                  type='button'
                  size='md'
                  block
                  onClick={removePrayer(index)}>
                  Remove
                </Button>
              </Form.Group>
            )}
          </Form.Row>
        );
      })}
      <Form.Row style={{ marginTop: '20px' }}>
        {!isEdit &&

          <Form.Group as={Col} xs={12}>
            <Button
              size='md'
              variant='secondary'
              type='button'
              onClick={addPrayer}>
              Add another slot
          </Button>
          </Form.Group>
        }
        <Form.Group as={Col} xs={12} style={{ marginTop: '30px' }}>
          <Button variant='primary' type='submit' block size={'lg'}>
            Submit
          </Button>
        </Form.Group>
      </Form.Row>
    </Form>
  );
};
