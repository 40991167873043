class Mosque {
  name: string;
  address: string;
  email: string;
  phone: string;
  url: string;
  notes: string;
  pending: string;

  constructor(
      name: string, address: string, email: string, phone: string, url: string,
      notes: string, pending: string) {
    this.name = name;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.url = url;
    this.notes = notes;
    this.pending = pending;
  }
}

export default Mosque;