import ViewWrapper from '../../components/theme/ViewWrapper';
import React, { Fragment, useContext, useState } from 'react';
import LoadingCircles from '../../components/loading/LoadingCircles';
import ResponsiveView from '../../components/ResponsiveView';
import { AuthContext } from '../../providers/AuthProvider';
import logo from '../../assets/logo trans-01-01.svg';
import AccentButton from '../../components/buttons/AccentButton';
import SecondaryButton from '../../components/buttons/SecondaryButton';
import styled from 'styled-components';
import ViewWithHeading from '../../components/ViewWithHeading';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import routes from '../../constants/routes';
import {useHistory} from 'react-router';
import HeadingText from '../../components/text/HeadingText';
import TitleText from '../../components/text/TitleText';
import SubheadingText from '../../components/text/SubheadingText';
import TextWrapper from '../../components/theme/TextWrapper';

const Image = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex-grow: 1;
`;

const Buttons = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 800px;
  font-size: 1em;
`;

function LoginView() {

  const authContext = useContext(AuthContext);
  const history = useHistory();

  function renderLoginView() {
    if (authContext.loading) {
      return <LoadingCircles size={120}>Signing you in</LoadingCircles>;
    } else {
      return (
        <Fragment>
          <Image>
            <img
              src={logo}
              width={'100%'}
              height={'auto'}
              alt={'Salah Space Logo'}
              style={{ maxWidth: '600px' }}
            />
          </Image>


          <Buttons>
            <AccentButton
              onClick={() => history.push(routes.LOGIN_EMAIL)}>
              Sign In
            </AccentButton>
            <SignUp onClick={() => history.push(routes.LOGIN_EMAIL_SIGN_UP)}>
              I don't have an account
            </SignUp>
          </Buttons>
        </Fragment>
      );
    }
  }

  return (
    <ViewWithHeading title={'Masjid Admin'}>
      <ViewWrapper
        style={{
          padding: '20px 10px',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
        }}>
        <ResponsiveView>
          {renderLoginView()}
        </ResponsiveView>
      </ViewWrapper>
    </ViewWithHeading>
  );
}

const SignUp = styled(TextWrapper)`
  font-size: 1.5em;
  font-weight: 700;
  color: white !important;
  padding: 20px 0 10px 0;
  width: 100%;
  text-decoration: underline;
  text-align: center;
`;


export default LoginView;
