import Slot from '../../models/Slot';
import React, {Fragment, useState} from 'react';
import TextWrapper from '../../components/theme/TextWrapper';
import {ProgressBar} from 'react-bootstrap';
import ListCard from '../../components/ListCard';
import {faDoorOpen} from '@fortawesome/free-solid-svg-icons/faDoorOpen';
import {faClock} from '@fortawesome/free-solid-svg-icons/faClock';
import SubheadingText from '../../components/text/SubheadingText';
import {Frame} from 'framer';
import styled from 'styled-components';
import DangerButton from '../../components/buttons/DangerButton';
import ViewWrapper from '../../components/theme/ViewWrapper';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import LoadingCircles from '../../components/loading/LoadingCircles';
import InfoAlert from '../../components/alerts/InfoAlert';
import {HStack, Stack} from '@chakra-ui/react'

function SlotCard(props: {slot: Slot, loadingMessage: string, onDelete: (Slot) => void, onEdit: (Slot) => void}) {

  const {slot, loadingMessage, onDelete, onEdit} = {...props};
  const [confirmDelete, setConfirmDelete] = useState(false);

  const maleCapacityPercentage = (slot.available_male / slot.capacity_male) * 100;
  const maleCapacityPercentageVariant =
      maleCapacityPercentage < 25
          ? 'danger'
          : maleCapacityPercentage > 75
          ? 'success'
          : 'info';
  const femaleCapacityPercentage = (slot.available_female / slot.capacity_female) * 100;
  const femaleCapacityPercentageVariant =
      maleCapacityPercentage < 25
          ? 'danger'
          : maleCapacityPercentage > 75
          ? 'success'
          : 'info';
  const canBeModified = slot.available_male === slot.capacity_male && slot.available_female === slot.capacity_female;
  // const canBeModified = true;

  const totalWorshippersMale = slot.capacity_male - slot.available_male;
  const totalWorshippersFemale = slot.capacity_female - slot.available_female;
  const totalWorshippers =  totalWorshippersMale + totalWorshippersFemale;

  function onDeletePress(slot) {
    setConfirmDelete(false)
    onDelete(slot)
  }

  function renderActionButtons() {
    if (loadingMessage ===  null) {
      if(confirmDelete) {
        return (
            <Fragment>
              <TextWrapper><b>Are you sure you want to delete this Slot?</b></TextWrapper>
              <ViewWrapper style={{display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                <PrimaryButton onClick={() => setConfirmDelete(false)}>Cancel</PrimaryButton>
                <DangerButton onClick={() => onDeletePress(slot)}>Delete!</DangerButton>
              </ViewWrapper>
            </Fragment>
        );
      } else {
        return (
            <Stack>
            <PrimaryButton onClick={() => onEdit(slot)}>Edit Slot</PrimaryButton>
              {canBeModified ? <DangerButton onClick={() => setConfirmDelete(true)}>Delete Slot</DangerButton>
                  : <InfoAlert>You cannot delete this slot as bookings already exist.</InfoAlert>}
            </Stack>
        )
      }
    }
  }

  return (
      <BookingCard>
        {loadingMessage && <Loading><LoadingCircles>{loadingMessage}</LoadingCircles></Loading>}
        <SlotContent blur={loadingMessage != null}>
          <TextWrapper style={{fontSize: '1.4em', fontWeight: '800', paddingBottom: '5px', textTransform: 'capitalize'}}>{slot.name}</TextWrapper>

          <ViewWrapper style={{display: 'flex', fontSize: '1.2em', width: '90%', justifyContent: 'space-around'}}>
            <ListCard.Detail icon={faDoorOpen}>Opens: {slot.timing.open}</ListCard.Detail>
            <ListCard.Detail icon={faClock}>Starts: {slot.timing.start}</ListCard.Detail>
          </ViewWrapper>

          <SubheadingText isDark={true} style={{marginTop: '15px'}}>Capacity Remaining</SubheadingText>
          <ViewWrapper style={{display: 'flex', fontSize: '1em', width: '100%', alignItems: 'center', marginBottom: '5px'}}>
            <TextWrapper style={{marginRight: '10px', width: '100px'}}>Male</TextWrapper>
            <ProgressBar
                variant={maleCapacityPercentageVariant}
                now={maleCapacityPercentage}
                label={slot.available_male + '/' + slot.capacity_male}
                style={{width: '100%'}}
            />
          </ViewWrapper>
          <ViewWrapper style={{display: 'flex', fontSize: '1em', width: '100%', alignItems: 'center', marginBottom: '5px'}}>
            <TextWrapper style={{marginRight: '10px', width: '100px'}}>Female</TextWrapper>
            <ProgressBar
                variant={femaleCapacityPercentageVariant}
                now={femaleCapacityPercentage}
                label={slot.available_female + '/' + slot.capacity_female}
                style={{width: '100%'}}
            />
          </ViewWrapper>

          <TextWrapper style={{fontSize: '1.4em', fontWeight: '800', paddingBottom: '5px', marginTop: '10px', textTransform: 'capitalize'}}>Total Worshippers: {totalWorshippers}</TextWrapper>

          <ViewWrapper style={{display: 'flex', fontSize: '1em', width: '100%', alignItems: 'center', marginBottom: '5px', flex: 1}}/>
          {renderActionButtons()}
        </SlotContent>
      </BookingCard>
  )
}

const BookingCard = styled(Frame)`
  border-radius: 30px;
  border: transparent solid 1px;
  background-color: white !important;
  height: 100% !important;
  margin-bottom: 20px;
  padding: 10px 20px;
  display: flex !important;;
  flex: 1 !important;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
  box-shadow: rgba(0,0,0,0.3) 0 0 10px;
  &:last-child {
  margin-right: 0;
  }
`;

const SlotContent = styled(ViewWrapper)`
  display: flex;
  flex-direction: column;
  opacity: ${props => props.blur ? 0.3 : 1};
  flex: 1;
  width: 100%;
  align-items: center;
`;


const Loading = styled(ViewWrapper)`
  position: absolute;
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
`;


export default SlotCard;
