import ViewWrapper from '../../components/theme/ViewWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextWrapper from '../../components/theme/TextWrapper';
import React from 'react';
import styled from 'styled-components';
import {COLOR_TEXT_DARK, COLOR_TEXT_LIGHT} from '../../constants/styles';

const BookingPanelDetail = (props: { color?: string, icon?: any, isDark?: boolean }) => {
  const color = props.isDark ? COLOR_TEXT_LIGHT : COLOR_TEXT_DARK;
  return (
    <ViewWrapper
      {...props}
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        paddingTop: '5px',
        paddingBottom: '5px',
        flex: 1,
        width: '100%',
      }}>
      {props.icon && (
        <FontAwesomeIcon
          icon={props.icon}
          color={color}
          size={'sm'}
          width={'20px'}
        />
      )}
      <TextWrapper
        style={{
          fontSize: '1em',
          marginLeft: '15px',
          color: color,
          alignItems: 'center',
          flex: 1,
        }}>
        {props.children}
      </TextWrapper>
    </ViewWrapper>
  );
};

export const BookingPanelDetailTitle = styled(TextWrapper)`
  font-weight: 800;
  color: white;
`;



export const BookingPanel = styled(ViewWrapper)`
  padding: 3px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  width: 100%;
  margin-bottom: 10px;
  color: white; 
`;

export default BookingPanelDetail;
