import React, { createContext, useEffect, useState } from 'react';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { FIREBASE_CONFIG } from '../config/firebaseConfig';
import routes from '../constants/routes';
import mosquesService from '../services/mosquesService';
import Logger from '../Logger';
import { addAxiosAuthHeader } from '../config/axiosConfig';
import Mosque from '../models/Mosque';
import MosqueWithSlots from '../models/MosqueWithSlots';
import Slot from '../models/Slot';
import mosquePrayersService from '../services/mosquePrayersService';

/**
 * This provider is created
 * to access user in whole app
 */

export const AuthContext = createContext({});

const STORAGE_MAGIC_LINK_EMAIL_KEY = 'AuthProvider.emailForMagicLinkSignIn';
// const STORAGE_EMAIL_KEY = "AuthProvider.emailForMagicLinkSignIn";

export const AuthProvider = ({ children }) => {
  const logger = new Logger(AuthProvider.name);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mosque: MosqueWithSlots, setMosque] = useState(null);
  const [slots: Array<Slot>, setSlots] = useState([]);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    logger.info('Initialising firebase', FIREBASE_CONFIG);
    firebase.initializeApp(FIREBASE_CONFIG);
    firebase.auth().onAuthStateChanged(onAuthStateChanged);
  }, []);

  async function onAuthStateChanged(firebaseUser) {
    setLoading(true);
    if (firebaseUser) {
      const idToken = await firebase.auth().currentUser.getIdToken(true);
      addAxiosAuthHeader(idToken);
      logger.info('firebaseUser', firebaseUser);
      try {
        const mosque = await mosquesService.getMosquesForUser(firebaseUser.uid);
        setMosque(mosque);
        setSlots(await mosquePrayersService.getPrayerSlots());
      } catch (e) {
        logger.error('failed to fetch mosque for user', firebaseUser.uid, e);
        setMosque(null);
      }
    }
    setUser(firebaseUser);
    setLoading(false);
  }

  async function refreshSlots() {
    try {
      setSlots(await mosquePrayersService.getPrayerSlots());
    } catch (e) {
      logger.error('failed to fetch slots for user', user.uid, e);
    }
  }

  async function doCreateUserWithEmailAndPassword(email: string, password: string) {
    logger.info('signing in with email ' + email);
    return await firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  async function doEmailSignIn(email: string, password: string) {
    logger.info('signing in with email ' + email);
    return await firebase.auth().signInWithEmailAndPassword(email, password);
  }

  async function doEmailSendPasswordResetEmail(email: string) {
    logger.info('sending password reset for email ' + email);
    return await firebase.auth().sendPasswordResetEmail(email, {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: `${window.location.origin + routes.LOGIN_EMAIL_PASSWORD_RESET}`,
      // This must be true.
      handleCodeInApp: true,
    });
  }

  async function signOut() {
    logger.info('signing out', user);
    return await firebase.auth().signOut();
  }

  function isLoggedIn() {
    const isLoggedIn = user != null;
    logger.info('is logged in', isLoggedIn);
    return isLoggedIn;
  }

  function hasAddedMosque() {
    return mosque != null;
  }

  function isMosqueStillPending() {
    return mosque == null || mosque.pending === "true" || mosque.pending;
  }

  function getMosque() {
    return mosque;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        showMenu, setShowMenu,
        loading,
        slots, setSlots,
        refreshSlots,
        hasAddedMosque,
        isMosqueStillPending,
        getMosque,
        setMosque,
        isLoggedIn,
        signOut,
        // Email
        doEmailSignIn,
        doEmailSendPasswordResetEmail,
        doCreateUserWithEmailAndPassword,
      }}>
      {children}
    </AuthContext.Provider>
  );
};
