import {Route, Switch, useLocation} from 'react-router';
import React from 'react';
import DefaultSlotsView from './DefaultSlotsView';
import routes from '../../constants/routes';
import SlotsCardView from './SlotsCardView';

function SlotsRoutes(props) {
  const location = useLocation();

  return (
      <Switch>
        <Route path={`${routes.SLOTS_ADD}`}>
          <DefaultSlotsView/>
        </Route>
        <Route>
          <SlotsCardView/>
        </Route>
      </Switch>
  );
}

export default SlotsRoutes;
