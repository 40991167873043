import React, { Fragment } from 'react';
import styled from 'styled-components';
import { COLOR_ACCENT } from '../../constants/styles';
import BaseButton from './BaseButton';

const AccentButton = styled(BaseButton)`
  border-radius: 10px;
  border-color: ${COLOR_ACCENT};
  border-style: solid;
  background-color: ${COLOR_ACCENT};
  color: white;
`;

export default AccentButton;
