import React from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { ErrorMessage } from '@hookform/error-message';
import { Form, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';

const schema = yup.object().shape({
  name: yup.string().required('Mosque name is required'),
  address: yup.string().required('Mosque address is required'),
  postcode: yup.string().required('Mosque postcode is required'),
  email: yup.string().email().required('Email is required'),
  phone: yup.string().required('Telephone is required'),
  url: yup.string(),
  charity_registration_number: yup.string(), //confirm if this attribute is required!
  trustee_name_1: yup.string(), //confirm if this attribute is required!
  trustee_phone_1: yup.string(), //confirm if this attribute is required!
  trustee_email_1: yup.string(), //confirm if this attribute is required!
  trustee_name_2: yup.string(), //confirm if this attribute is required!
  trustee_phone_2: yup.string(), //confirm if this attribute is required!
  trustee_email_2: yup.string(), //confirm if this attribute is required!
  confirm: yup
    .object()
    .shape({
      safety: yup
        .bool()
        .oneOf([true], 'You must agree before submitting')
        .required('You must agree before submitting'),
      liability: yup
        .bool()
        .oneOf([true], 'You must agree before submitting')
        .required('You must agree before submitting'),
      data: yup.object({
        third_party: yup
          .bool()
          .oneOf([true], 'You must agree before submitting')
          .required('You must agree before submitting'),
        secure_destroy: yup
          .bool()
          .oneOf([true], 'You must agree before submitting')
          .required('You must agree before submitting'),
        no_marketing_no_fundraising: yup
          .bool()
          .oneOf([true], 'You must agree before submitting')
          .required('You must agree before submitting'),
      }),
    })
    .required('You must agree before submitting'),
});

type Props = {
  onComplete: () => {},
};

export const MosquesForm = ({ onComplete = async () => { } }) => {
  const { register, handleSubmit, errors } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    // return the data back to the parent component to handle next
    await onComplete(data);
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Form.Group controlId='name'>
        <HeadingLabel style={{ fontWeight: 700 }}>Mosque Name</HeadingLabel>
        <Form.Control
          name='name'
          placeholder='Mosque Name'
          ref={register}
          isInvalid={errors?.name}
          size={'lg'}
        />
        <ErrorMessage
          errors={errors}
          name='name'
          as={Form.Control.Feedback}
          type='invalid'
        />
      </Form.Group>

      <Form.Group controlId='address'>
        <HeadingLabel>Mosque Address</HeadingLabel>
        <Form.Control
          name='address'
          as='textarea'
          rows='2'
          placeholder='Mosque Address'
          ref={register}
          isInvalid={errors?.address}
          size={'lg'}
        />
        <ErrorMessage
          errors={errors}
          name='address'
          as={Form.Control.Feedback}
          type='invalid'
        />
      </Form.Group>

      <Form.Group controlId='postcode'>
        <HeadingLabel>Mosque Postcode</HeadingLabel>
        <Form.Control
          name='postcode'
          placeholder='Mosque Postcode'
          ref={register}
          isInvalid={errors?.postcode}
          size={'lg'}
        />
        <ErrorMessage
          errors={errors}
          name='postcode'
          as={Form.Control.Feedback}
          type='invalid'
        />
      </Form.Group>

      <Form.Row>
        <Form.Group as={Col} xs={12} md={6} controlId='email'>
          <HeadingLabel>Mosque Email</HeadingLabel>
          <Form.Control
            type='email'
            name='email'
            placeholder='Mosque Email'
            ref={register}
            isInvalid={errors?.email}
            size={'lg'}
          />
          <ErrorMessage
            errors={errors}
            name='email'
            as={Form.Control.Feedback}
            type='invalid'
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} md={6} controlId='phone'>
          <HeadingLabel>Mosque Phone</HeadingLabel>
          <Form.Control
            name='phone'
            placeholder='Mosque Phone'
            ref={register}
            isInvalid={errors?.phone}
            size={'lg'}
          />
          <ErrorMessage
            errors={errors}
            name='phone'
            as={Form.Control.Feedback}
            type='invalid'
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} controlId='url'>
          <HeadingLabel>Mosque Website</HeadingLabel>
          <Form.Control
            name='url'
            placeholder='Mosque Website'
            ref={register}
            isInvalid={errors?.url}
            size={'lg'}
          />
          <ErrorMessage
            errors={errors}
            name='url'
            as={Form.Control.Feedback}
            type='invalid'
          />
        </Form.Group>
        <Form.Group as={Col} xs={12} controlId='charity_registration_number'>
          <HeadingLabel>Charity Registration Number</HeadingLabel>
          <Form.Control
            name='charity_registration_number'
            placeholder='Charity Registration Number'
            ref={register}
            isInvalid={errors?.charity_registration_number}
            size={'lg'}
          />
          <ErrorMessage
            errors={errors}
            name='charity_registration_number'
            as={Form.Control.Feedback}
            type='invalid'
          />
        </Form.Group>
        {/* <div className="border border-secondary rounded w-100 mb-3 py-3">
          <Form.Group as={Col} xs={12} controlId='trustee_name_1'>
            <HeadingLabel>1st Trustee Details</HeadingLabel>
            <div className="pt-3">
              <Form.Control
                name='trustee_name_1'
                placeholder='1st Trustee Name'
                ref={register}
                isInvalid={errors?.trustee_name_1}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_name_1'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </div>
          </Form.Group>
          <div className="d-md-flex flex-row">
            <Form.Group as={Col} xs={12} md={6} controlId='trustee_phone_1'>
              <Form.Control
                name='trustee_phone_1'
                placeholder='1st Trustee Phone'
                ref={register}
                isInvalid={errors?.trustee_phone_1}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_phone_1'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} controlId='trustee_email_1'>
              <Form.Control
                name='trustee_email_1'
                placeholder='1st Trustee Email'
                ref={register}
                isInvalid={errors?.trustee_email_1}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_email_1'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </Form.Group>
          </div>
        </div>
        <div className="border border-secondary rounded w-100 mb-3 py-3">
          <Form.Group as={Col} xs={12} controlId='trustee_name_2'>
            <HeadingLabel>2nd Trustee Details</HeadingLabel>
            <div className="pt-3">
              <Form.Control
                name='trustee_name_2'
                placeholder='2nd Trustee Name'
                ref={register}
                isInvalid={errors?.trustee_name_2}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_name_2'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </div>
          </Form.Group>
          <div className="d-md-flex flex-row">
            <Form.Group as={Col} xs={12} md={6} controlId='trustee_phone_2'>
              <Form.Control
                name='trustee_phone_2'
                placeholder='2nd Trustee Phone'
                ref={register}
                isInvalid={errors?.trustee_phone_2}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_phone_2'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </Form.Group>
            <Form.Group as={Col} xs={12} md={6} controlId='trustee_email_2'>
              <Form.Control
                name='trustee_email_2'
                placeholder='2nd Trustee Email'
                ref={register}
                isInvalid={errors?.trustee_email_2}
                size={'lg'}
              />
              <ErrorMessage
                errors={errors}
                name='trustee_email_2'
                as={Form.Control.Feedback}
                type='invalid'
              />
            </Form.Group>
          </div>
        </div> */}
      </Form.Row>

      <Form.Group controlId='confirm.safety'>
        <Form.Check
          type='checkbox'
          name='confirm.safety'
          ref={register}
          label='I can confirm that we, the mosque, have carried out the necessary steps to ensure the capacity for each Salah is compliant with government regulations.'
          isInvalid={errors?.confirm?.safety}
          feedback='You must agree before submitting.'
        />
      </Form.Group>
      <Form.Group controlId='confirm.liability'>
        <Form.Check
          type='checkbox'
          name='confirm.liability'
          ref={register}
          label='I accept that Salah Space holds no liability and we, as a mosque, understand that this is just a platform for people to book slots for their prayers. We are still responsible.'
          isInvalid={errors?.confirm?.liability}
          feedback='You must agree before submitting.'
        />
      </Form.Group>
      <Form.Group controlId='confirm.data.third_party'>
        <Form.Check
          type='checkbox'
          name='confirm.data.third_party'
          ref={register}
          label='If data is shared with my mosque, we will not share the data of individuals with third parties.'
          isInvalid={errors?.confirm?.data?.third_party}
          feedback='You must agree before submitting.'
        />
      </Form.Group>
      <Form.Group controlId='confirm.data.secure_destroy'>
        <Form.Check
          type='checkbox'
          name='confirm.data.secure_destroy'
          ref={register}
          label='I agree to take necessary steps to ensure that any printed material is securely destroyed after the intended use has expired.'
          isInvalid={errors?.confirm?.data?.secure_destroy}
          feedback='You must agree before submitting.'
        />
      </Form.Group>
      <Form.Group controlId='confirm.data.no_marketing_no_fundraising'>
        <Form.Check
          type='checkbox'
          name='confirm.data.no_marketing_no_fundraising'
          ref={register}
          label='I agree to not use information shared for the purpose of marketing or fundraising.'
          feedback='You must agree before submitting.'
          isInvalid={errors?.confirm?.data?.no_marketing_no_fundraising}
        />
        <ErrorMessage
          errors={errors}
          name='confirm.data.no_marketing_no_fundraising'
          as={Form.Control.Feedback}
          type='invalid'
        />
      </Form.Group>

      <Form.Group controlId='notes'>
        <HeadingLabel>
          Special notes or instructions for Mosque attendees
        </HeadingLabel>
        <Form.Control
          as='textarea'
          rows='5'
          name='notes'
          placeholder='E.g. always wear a mask etc.'
          ref={register}
        />
      </Form.Group>

      <Form.Group style={{ marginTop: '20px', marginBottom: '40px' }}>
        <Button variant='primary' type='submit' size={'lg'} block>
          Submit
        </Button>
      </Form.Group>
    </Form>
  );
};

const HeadingLabel = styled(Form.Label)`
  font-weight: 700;
  font-size: 1.1em;
`;
